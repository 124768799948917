.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
  
	> {
	  .form-control, .custom-select, .custom-file {
		position: relative;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		width: 1%;
		margin-bottom: 0;
	  }
  
	  .form-control:focus, .custom-select:focus, .custom-file:focus {
		z-index: 3;
	  }
  
	  .form-control + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .custom-select + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .custom-file + {
		.form-control, .custom-select, .custom-file {
		  margin-left: -1px;
		}
	  }
  
	  .form-control:not(:last-child), .custom-select:not(:last-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	  }
  
	  .form-control:not(:first-child), .custom-select:not(:first-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	  }
  
	  .custom-file {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
  
		&:not(:last-child) .custom-file-label {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
  
		  &::after {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		  }
		}
  
		&:not(:first-child) .custom-file-label {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
  
		  &::after {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		  }
		}
	  }
	}
  }
  
  .input-group-prepend, .input-group-append {
	display: -ms-flexbox;
	display: flex;
  }
  
  .input-group-prepend .btn, .input-group-append .btn {
	position: relative;
	z-index: 0;
  }
  
  .input-group-prepend {
	.btn + {
	  .btn, .input-group-text {
		margin-left: -1px;
	  }
	}
  
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  }
  
  .input-group-append {
	.btn + {
	  .btn, .input-group-text {
		margin-left: -1px;
	  }
	}
  
	.input-group-text + {
	  .input-group-text, .btn {
		margin-left: -1px;
	  }
	}
  }
  
  .input-group-prepend {
	margin-right: -1px;
  }
  
  .input-group-append {
	margin-left: -1px;
  }
  
  .input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 0.9;
	color: #455b7e;
	text-align: center;
	white-space: nowrap;
	background-color: $white;
	border-radius: 0px 4px 4px 0px;
	border: 1px solid $border;
  
	input {
	  &[type="radio"], &[type="checkbox"] {
		margin-top: 0;
	  }
	}
  }
  
  .input-group > {
	.input-group-prepend > {
	  .btn, .input-group-text {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	  }
	}
  
	.input-group-append {
	  &:not(:last-child) > {
		.btn, .input-group-text {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
		}
	  }
  
	  &:last-child > {
		.btn:not(:last-child):not(.dropdown-toggle), .input-group-text:not(:last-child) {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
		}
	  }
  
	  > {
		.btn, .input-group-text {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
		  border-top-left-radius: 4px;
		  border-bottom-left-radius: 4px;
		}
	  }
	}
  
	.input-group-prepend {
	  &:not(:first-child) > {
		.btn, .input-group-text {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
		  border-top-left-radius: 4px;
		  border-bottom-left-radius: 4px;
		}
	  }
  
	  &:first-child > {
		.btn:not(:first-child), .input-group-text:not(:first-child) {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
		  border-top-left-radius: 4px;
		  border-bottom-left-radius: 4px;
		}
	  }
	}
  }
  
  .input-group-addon {
	padding: 10px 0px 6px 12px;
	width: 38px;
	height: 38px;
	font-size: 20px;
	font-weight: 400;
	line-height: 1 !important;
	color: #555;
	text-align: center;
	border-right: 0;
	border-radius: 4px 0 0 4px;
  }
  
  .input-indec .input-group-btn > .btn {
	height: 100%;
	border-color: $border;
  }
  
  .input100 {
	padding: 0 54px 0 30px !important;
  }
  
  .input-lg {
	height: 46px !important;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 4px;
  }
  
  .input-sm {
	height: 30px !important;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 4px;
  }
  
  .input-group-prepend, .input-group-append, .input-group-btn {
	font-size: 0.9375rem;
  }
  
  .input-group-prepend > .btn, .input-group-append > .btn, .input-group-btn > .btn {
	height: 100%;
	border-color: rgb(36, 196, 180);
  }
  
  .input-group-prepend > .input-group-text, .input-group-append > .input-group-text {
	border-left: 0;
  }
  
  .input-icon {
	position: relative;
  }
  
  .input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	color: $white;
  
	&.search-icon {
	  right: auto !important;
	  left: 0 !important;
	  color: #636262 !important;
	}
  
	&:last-child {
	  left: auto;
	  right: 0;
	}
  }
  
  .input-icon .form-group.is-empty {
	.form-control {
	  margin-bottom: 0;
	}
  
	margin-bottom: 0;
  }
  
  input {
	&.error {
	  border: 1px solid #ff0000 !important;
	}
  
	&.valid {
	  border: 1px solid #4ecc48 !important;
	}
  
	&[type="search"] {
	  &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
		-webkit-appearance: none;
	  }
	}
  
	&[type=search] {
	  &::-ms-clear, &::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	  }
	}
  
	&[type=text]::-ms-clear {
	  display: none;
	}
  }