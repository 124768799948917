.panel-body-landing:hover > .table > tbody > tr > td {
	color: $white !important;
	-webkit-transition: all .3s ease;
  }
  
  .panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #1643A3 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
  }
  
  .panel-heading-landing-box {
	background: #1643A3 !important;
	color: $white !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
  }
  
  .panel-title-landing {
	color: #1643A3 !important;
	font-size: 35px;
	font-weight: bold;
  }
  
  .panel-body-landing {
	border: solid 2px #1643A3 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
  }
  
  .panel-footer-landing {
	border: solid 2px #1643A3 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
  }
  
  /*------ Pricing Styles ---------*/
  
  .panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
  
	> .panel-heading {
	  -moz-transition: all .3s ease;
	  -o-transition: all .3s ease;
	  -webkit-transition: all .3s ease;
	}
  
	h3 {
	  margin-bottom: 0;
	  padding: 20px 0;
	}
  }
  
  .panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	background: $white;
  }
  
  .panel.price {
	> .panel-heading {
	  color: $white;
	}
  
	.list-group-item {
	  &:last-child {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	  }
  
	  &:first-child {
		border-top-right-radius: 0px;
		border-top-left-radius: 0px;
	  }
	}
  
	margin-bottom: 1.5rem;
	box-shadow: 0px 22px 68px -9px rgba(0, 0, 0, 0.15);
  
	&:hover {
	  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	}
  }
  
  .price {
	.panel-footer {
	  border-bottom: 0px;
	  background-color: $white;
	  border-left: 0;
	  border-right: 0;
	}
  
	&.panel-color > .panel-body {
	  background-color: $white;
	}
  }
  
  .panel-body {
	padding: 15px;
  
	.lead {
	  strong {
		font-size: 40px;
		margin-bottom: 0;
	  }
  
	  font-size: 20px;
	  margin-bottom: 0;
	  padding: 10px 0;
	}
  }
  
  .panel-footer {
	padding: 10px 15px;
	background-color: $white;
	border-top: 1px solid $border;
	border-left: 1px solid $border;
	border-right: 1px solid $border;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
  }
  
  .panel.price .btn {
	box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
	border: 0px;
  }
  
  /*-------Accordion-----------*/
  
  .panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: $border;
  }
  
  .panel-default > .panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: rgba(236, 238, 249, 0.9);
	border-color: $border;
  }
  
  .panel-title {
	font-size: 14px;
	margin-bottom: 0;
  
	> a {
	  display: block;
	  padding: 15px;
	  text-decoration: none;
	}
  }
  
  .more-less {
	float: right;
	color: #212121;
  }
  
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border: 1px solid $border;
  }
  
  /* ----- v CAN BE DELETED v ----- */
  
  .wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
  }
  
  a {
	&:focus, &:hover, &:active {
	  outline: 0;
	  text-decoration: none;
	}
  }
  
  .panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  
  .panel-group1 {
	> .panel1:first-child .panel-heading1 {
	  border-radius: 4px 4px 0 0;
	}
  
	.panel1 {
	  border-radius: 0;
	}
  }
  
  .panel-body1 {
	padding: 10px;
  }
  
  .panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .panel-group1 .panel1 + .panel1 {
	margin-top: 0;
  }
  
  .panel-heading1 {
	background-color: #5797fc;
	border-radius: 0;
	border: none;
	color: $white;
	padding: 0;
  }
  
  .panel-group1 .panel-body {
	border: 1px solid $border;
  }
  
  .panel-title1 a {
	display: block;
	color: $white;
	padding: 10px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
  }
  
  .panel-body1 {
	background: $white;
  }
  
  .panel1:last-child {
	.panel-body1 {
	  border-radius: 0 0 4px 4px;
	}
  
	.panel-heading1 {
	  border-radius: 0 0 4px 4px;
	  transition: border-radius 0.3s linear 0.2s;
  
	  &.active {
		border-radius: 0;
		transition: border-radius linear 0s;
	  }
	}
  }
  
  /* #bs-collapse icon scale option */
  
  .panel-title a.accordion-toggle {
	&:before {
	  content: "\f068";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  
	&.collapsed:before {
	  content: "\f067";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  }
  
  .panel-heading1 {
	a {
	  &.collapsed:before {
		content: "\e9af";
		position: absolute;
		font-family: 'feather' !important;
		left: 10px;
		top: 7px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
		right: auto;
	  }
  
	  &:before {
		content: "\e994";
		position: absolute;
		font-family: 'feather' !important;
		left: 10px;
		top: 7px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
	  }
	}
  
	&.active a:before {
	  content: ' ';
	  transition: all 0.5s;
	  transform: scale(0);
	}
  }