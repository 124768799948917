table {
	&.dataTable thead {
	  .sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting_asc_disabled:before, .sorting_desc_disabled:before {
		right: auto !important;
		left: 1rem;
		content: "\2191";
	  }
  
	  .sorting:after, .sorting_asc:after, .sorting_desc:after, .sorting_asc_disabled:after, .sorting_desc_disabled:after {
		right: auto !important;
		left: 0.5rem;
		content: "\2191";
	  }
  
	  > tr > {
		th {
		  &.sorting_asc, &.sorting_desc, &.sorting {
			padding-right: 0.75rem !important;
			padding-left: 30px;
		  }
		}
  
		td {
		  &.sorting_asc, &.sorting_desc, &.sorting {
			padding-right: 0.75rem !important;
			padding-left: 30px;
		  }
		}
	  }
	}
  
	&.table-bordered.dataTable {
	  th, td {
		border-left-width: 1px !important;
		border-right-width: 0px;
	  }
  
	  th:last-child, td:last-child {
		border-right-width: 0;
		border-left-width: 0px !important;
	  }
	}
  }
  
  /*------ Tables -----*/
  
  .table {
	color: $color;
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
  }
  
  .text-wrap table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
  }
  
  .table th {
	padding: 0.75rem;
	vertical-align: top;
  }
  
  .text-wrap table {
	th, td {
	  padding: 0.75rem;
	  vertical-align: top;
	}
  }
  
  .table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid $border;
  }
  
  .border {
	padding: 0.75rem;
	vertical-align: top;
	border: 1px solid $border;
  }
  
  .table thead th, .text-wrap table thead th {
	vertical-align: bottom;
	border-bottom: 1px solid $border;
  }
  
  .table tbody + tbody, .text-wrap table tbody + tbody {
	border-top: 2px solid $border;
  }
  
  .table .table, .text-wrap table .table, .table .text-wrap table {
	background-color: #e5e6f1;
  }
  
  .text-wrap {
	.table table, table table {
	  background-color: #e5e6f1;
	}
  }
  
  .table-sm {
	th, td {
	  padding: 0.3rem;
	}
  }
  
  .table-bordered, .text-wrap table, .table-bordered th, .text-wrap table th, .table-bordered td, .text-wrap table td {
	border: 1px solid $border;
  }
  
  .table-bordered thead th, .text-wrap table thead th, .table-bordered thead td, .text-wrap table thead td {
	border-bottom-width: 2px;
  }
  
  .table-borderless {
	th, td, thead th, tbody + tbody {
	  border: 0;
	}
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.02);
  }
  
  .table-hover tbody {
	tr:hover, th {
	  background-color: rgba(236, 238, 249, 0.9);
	}
  }
  
  .table-primary {
	background-color: #d5d5f1;
  
	> {
	  th, td {
		background-color: #d5d5f1;
	  }
	}
  }
  
  .table-hover .table-primary:hover {
	background-color: #d2f9da;
  
	> {
	  td, th {
		background-color: #d2f9da;
	  }
	}
  }
  
  .table-secondary {
	background-color: #cfe8f9;
  
	> {
	  th, td {
		background-color: #cfe8f9;
	  }
	}
  }
  
  .table-hover .table-secondary:hover {
	background-color: #ffecca;
  
	> {
	  td, th {
		background-color: #ffecca;
	  }
	}
  }
  
  .table-success {
	background-color: #d2ecb8;
  
	> {
	  th, td {
		background-color: #d2ecb8;
	  }
	}
  }
  
  .table-hover .table-success:hover {
	background-color: #c5e7a4;
  
	> {
	  td, th {
		background-color: #c5e7a4;
	  }
	}
  }
  
  .table-info {
	background-color: #cbe7fb;
  
	> {
	  th, td {
		background-color: #cbe7fb;
	  }
	}
  }
  
  .table-hover .table-info:hover {
	background-color: #b3dcf9;
  
	> {
	  td, th {
		background-color: #b3dcf9;
	  }
	}
  }
  
  .table-warning {
	background-color: #f1d5a7;
  
	> {
	  th, td {
		background-color: #f1d5a7;
	  }
	}
  }
  
  .table-hover .table-warning:hover {
	background-color: #fae8a4;
  
	> {
	  td, th {
		background-color: #fae8a4;
	  }
	}
  }
  
  .table-danger {
	background-color: #f1c1c0;
  
	> {
	  th, td {
		background-color: #f1c1c0;
	  }
	}
  }
  
  .table-hover .table-danger:hover {
	background-color: #ecacab;
  
	> {
	  td, th {
		background-color: #ecacab;
	  }
	}
  }
  
  .table-light {
	background-color: #fdfdfe;
  
	> {
	  th, td {
		background-color: #fdfdfe;
	  }
	}
  }
  
  .table-hover .table-light:hover {
	background-color: #ececf6;
  
	> {
	  td, th {
		background-color: #ececf6;
	  }
	}
  }
  
  .table-dark {
	background-color: #c6c8ca;
  
	> {
	  th, td {
		background-color: #c6c8ca;
	  }
	}
  }
  
  .table-hover .table-dark:hover {
	background-color: #b9bbbe;
  
	> {
	  td, th {
		background-color: #b9bbbe;
	  }
	}
  }
  
  .table-active {
	background-color: rgba(0, 0, 0, 0.04);
  
	> {
	  th, td {
		background-color: rgba(0, 0, 0, 0.04);
	  }
	}
  }
  
  .table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.04);
  
	> {
	  td, th {
		background-color: rgba(0, 0, 0, 0.04);
	  }
	}
  }
  
  .table .thead-dark th, .text-wrap table .thead-dark th {
	color: #e5e6f1;
	background-color: #212529;
	border-color: #32383e;
  }
  
  .table .thead-light th, .text-wrap table .thead-light th {
	color: #455b7e;
	background-color: #e5e6f1;
	border-color: $border;
  }
  
  .table-dark {
	color: #e5e6f1;
	background-color: #212529;
  
	th, td, thead th {
	  border-color: #32383e;
	}
  
	&.table-bordered {
	  border: 0;
	}
  }
  
  .text-wrap table.table-dark {
	border: 0;
  }
  
  .table-dark {
	&.table-striped tbody tr:nth-of-type(odd) {
	  background-color: rgba(255, 255, 255, 0.05);
	}
  
	&.table-hover tbody tr:hover {
	  background-color: rgba(255, 255, 255, 0.075);
	}
  }
  
  .table.dataTable thead th {
	border-bottom: 0 !important;
  }
  
  @media (max-width: 575.98px) {
	.table-responsive-sm {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-sm > table {
	  border: 0;
	}
  }
  
  @media (max-width: 767.98px) {
	.table-responsive-md {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-md > table {
	  border: 0;
	}
  }
  
  @media (max-width: 991.98px) {
	.table-responsive-lg {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-lg > table {
	  border: 0;
	}
  }
  
  @media (max-width: 1279.98px) {
	.table-responsive-xl {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-xl > table {
	  border: 0;
	}
  }
  
  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  
	> .table-bordered {
	  border: 0;
	}
  }
  
  .text-wrap .table-responsive > table {
	border: 0;
  }
  
  .table thead th, .text-wrap table thead th {
	border-bottom-width: 1px;
	padding-top: .5rem;
	padding-bottom: .5rem;
  }
  
  .table th, .text-wrap table th {
	color: $color;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 400;
  }
  
  .table-md {
	th, td {
	  padding: .5rem;
	}
  }
  
  .table-vcenter {
	td, th {
	  vertical-align: middle;
	  border-top: 1px solid $border;
	}
  }
  
  .table-center {
	td, th {
	  text-align: center;
	}
  }
  
  .table-striped tbody tr {
	&:nth-of-type(odd) {
	  background: transparent;
	}
  
	&:nth-of-type(even) {
	  background-color: $white;
	}
  }
  
  .table-calendar {
	margin: 0 0 .75rem;
  
	td, th {
	  border: 0;
	  text-align: center;
	  padding: 0 !important;
	  width: 14.28571429%;
	  line-height: 2.5rem;
	}
  
	td {
	  border-top: 0;
	}
  }
  
  .table-calendar-link {
	line-height: 2rem;
	min-width: calc(2rem + 2px);
	display: inline-block;
	border-radius: 4px;
	background: #e7e9f5;
	color: #455b7e;
	font-weight: 600;
	transition: .3s background, .3s color;
	position: relative;
  
	&:before {
	  content: '';
	  width: 4px;
	  height: 4px;
	  position: absolute;
	  left: .25rem;
	  top: .25rem;
	  border-radius: 50px;
	  background: #467fcf;
	}
  
	&:hover {
	  color: $white;
	  text-decoration: none;
	  background: #467fcf;
	  transition: .3s background;
  
	  &:before {
		background: $white;
	  }
	}
  }
  
  .table-header {
	cursor: pointer;
	transition: .3s color;
  
	&:hover {
	  color: #455b7e !important;
	}
  
	&:after {
	  content: '\f0dc';
	  font-family: FontAwesome;
	  display: inline-block;
	  margin-left: .5rem;
	  font-size: .75rem;
	}
  }
  
  .table-header-asc {
	color: #455b7e !important;
  
	&:after {
	  content: '\f0de';
	}
  }
  
  .table-header-desc {
	color: #455b7e !important;
  
	&:after {
	  content: '\f0dd';
	}
  }
  
  div {
	&.dataTables_wrapper div.dataTables_filter {
	  text-align: left !important;
	}
  
	&.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
	  padding-right: 0.44rem !important;
	  padding-left: 0;
	}
  
	&.dataTables_wrapper div.dataTables_filter input {
	  margin-left: 0rem !important;
	  display: inline-block;
	  width: auto;
	  margin-right: 0.5rem;
	}
  }
  
  @media (max-width: 768px) {
	.dataTables_wrapper {
	  .dataTables_info, .dataTables_paginate {
		margin-top: 10px !important;
	  }
	}
  
	div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	  justify-content: center !important;
	}
  }