/*------ Card -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: $white;
	border: 0;
	background-clip: border-box;
	box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
	border-radius: 4px;
  
	> {
	  hr {
		margin-right: 0;
		margin-left: 0;
	  }
  
	  .list-group {
		&:first-child .list-group-item:first-child {
		  border-top-left-radius: 4px;
		  border-top-right-radius: 4px;
		}
  
		&:last-child .list-group-item:last-child {
		  border-bottom-right-radius: 7px;
		  border-bottom-left-radius: 7px;
		}
	  }
	}
  }
  
  .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
  }
  
  .card-title {
	margin-bottom: 1.5rem;
  }
  
  .card-subtitle {
	margin-top: -0.75rem;
	margin-bottom: 0;
  }
  
  .card-text:last-child {
	margin-bottom: 0;
  }
  
  .card-link {
	&:hover {
	  text-decoration: none;
	}
  
	+ .card-link {
	  margin-left: 1.5rem;
	}
  }
  
  .card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid $border;
  
	&:first-child {
	  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
	}
  
	+ .list-group .list-group-item:first-child {
	  border-top: 0;
	}
  }
  
  .card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid $border;
  }
  
  .card-header-tabs {
	margin-right: -0.75rem;
	margin-bottom: -1.5rem;
	margin-left: -0.75rem;
	border-bottom: 0;
  }
  
  .card-header-pills {
	margin-right: -0.75rem;
	margin-left: -0.75rem;
  }
  
  .card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
  }
  
  .card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
  }
  
  .card-img-top {
	width: 100%;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
  }
  
  .card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(3px - 1px);
	border-bottom-left-radius: calc(3px - 1px);
  }
  
  .card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
  
	.card {
	  margin-bottom: 0.75rem;
	}
  }
  
  @media (min-width: 576px) {
	.card-deck {
	  -ms-flex-flow: row wrap;
	  flex-flow: row wrap;
	  margin-right: -0.75rem;
	  margin-left: -0.75rem;
  
	  .card {
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1 0 0%;
		flex: 1 0 0%;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: 0.75rem;
		margin-bottom: 0;
		margin-left: 0.75rem;
	  }
	}
  }
  
  .card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
  
	> .card {
	  margin-bottom: 0.75rem;
	}
  }
  
  @media (min-width: 576px) {
	.card-group {
	  -ms-flex-flow: row wrap;
	  flex-flow: row wrap;
  
	  > .card {
		-ms-flex: 1 0 0%;
		flex: 1 0 0%;
		margin-bottom: 0;
  
		+ .card {
		  margin-left: 0;
		  border-left: 0;
		}
  
		&:first-child {
		  border-top-right-radius: 0;
		  border-bottom-right-radius: 0;
  
		  .card-img-top, .card-header {
			border-top-right-radius: 0;
		  }
  
		  .card-img-bottom, .card-footer {
			border-bottom-right-radius: 0;
		  }
		}
  
		&:last-child {
		  border-top-left-radius: 0;
		  border-bottom-left-radius: 0;
  
		  .card-img-top, .card-header {
			border-top-left-radius: 0;
		  }
  
		  .card-img-bottom, .card-footer {
			border-bottom-left-radius: 0;
		  }
		}
  
		&:only-child {
		  border-radius: 4px;
  
		  .card-img-top, .card-header {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		  }
  
		  .card-img-bottom, .card-footer {
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
		  }
		}
  
		&:not(:first-child):not(:last-child):not(:only-child) {
		  border-radius: 0;
  
		  .card-img-top, .card-img-bottom, .card-header, .card-footer {
			border-radius: 0;
		  }
		}
	  }
	}
  }
  
  .card-columns .card {
	margin-bottom: 1.5rem;
  }
  
  @media (min-width: 576px) {
	.card-columns {
	  -webkit-column-count: 3;
	  -moz-column-count: 3;
	  column-count: 3;
	  -webkit-column-gap: 1.25rem;
	  -moz-column-gap: 1.25rem;
	  column-gap: 1.25rem;
	  orphans: 1;
	  widows: 1;
  
	  .card {
		display: inline-block;
		width: 100%;
	  }
	}
  }
  
  .card-padding {
	padding: 14px 2px 2px 11px;
  }
  
  .card-items {
	padding: 10px;
  }
  
  .card .box {
	.img img {
	  width: 150px;
	  height: 150px;
	  border-radius: 100%;
	}
  
	h2 {
	  font-size: 20px;
	  color: #262626;
	  margin: 20px auto;
  
	  span {
		font-size: 14px;
		color: $white;
		display: inline-block;
		padding: 4px 10px;
		border-radius: 15px;
	  }
	}
  
	p {
	  color: #262626;
	}
  }
  
  .card-counter {
	padding: 20px 10px;
	background-color: $white;
	height: 100px;
	border-radius: 7px;
	transition: .3s linear all;
	margin-bottom: 1.5rem;
  
	&:hover {
	  transition: .3s linear all;
	}
  
	&.success {
	  background: linear-gradient(to bottom right, #62fb62, #21a544) !important;
	  color: #FFF;
	}
  
	&.info {
	  background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
	  color: #FFF;
	}
  
	i {
	  font-size: 5em;
	  opacity: 0.3;
	}
  
	.count-numbers {
	  position: absolute;
	  right: 35px;
	  top: 10px;
	  font-size: 32px;
	  display: block;
	}
  
	.count-name {
	  position: absolute;
	  right: 35px;
	  top: 65px;
	  text-transform: capitalize;
	  opacity: 0.5;
	  display: block;
	  font-size: 18px;
	}
  }
  
  .card-label {
	border: 0;
  
	&.list-group-item {
	  border-bottom: 0;
	}
  }
  
  .card-img-holder i {
	font-size: 45px;
  }
  
  .card-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
  }
  
  .card-aside-img {
	img {
	  width: 80px;
	  height: 80px;
	  vertical-align: middle;
	}
  
	&.wrap-border img {
	  border: 1px solid rgba(167, 180, 201, 0.3);
	  padding: 3px;
	}
  
	position: relative;
  
	a {
	  position: absolute;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 1;
	}
  }
  
  .card-item-desc {
	padding: 15px;
  
	h6 {
	  margin-top: 5px;
	}
  }
  
  .card-blogs .card {
	border: none;
	box-shadow: none;
  }
  
  .card-blogs1 {
	.card {
	  box-shadow: none;
	  border-bottom: 1px solid rgba(167, 180, 201, 0.3);
	  padding-bottom: 15px;
	  margin-bottom: 10px;
  
	  &:last-child {
		box-shadow: none;
		border-bottom: none;
		padding-bottom: 0;
		margin-bottom: 0;
	  }
	}
  
	.card-item-desc .card-item-price {
	  .newprice {
		font-weight: 600;
	  }
  
	  .oldprice {
		font-size: 14px;
	  }
	}
  }
  
  .card-blogs2 .card {
	border: none;
	box-shadow: none;
  }
  
  .card-over {
	position: relative;
	overflow: hidden;
	background: $white;
	margin-bottom: 0.8rem;
	border: 1px solid $border;
  }
  
  .card-over-data {
	position: absolute;
	width: 100%;
  }
  
  @media (max-width: 767px) {
	.card-tabs {
	  display: block !important;
	}
  }
  
  .card-blog-overlay {
	background: url('../images/thumbnails/thumb2.jpg');
	background-size: cover;
	position: relative;
  
	&:before {
	  content: '';
	  display: block;
	  position: absolute;
	  background: rgba(0, 0, 0, 0.5);
	  width: 100%;
	  height: 100%;
	  right: 0;
	  top: 0;
	}
  }
  
  .card-blog-overlay1:before, .card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
  }
  
  .card-blog-overlay1 {
	background: url('../images/thumbnails/thumb3.jpg');
	background-size: cover;
  }
  
  .card-blog-overlay2 {
	background: url('../images/photos/22.jpg');
	background-size: cover;
  }
  
  /*------ Default Card Values -------*/
  
  .card {
	position: relative;
	margin-bottom: 0.55rem;
	width: 100%;
  }
  
  .card-header:before {
	content: "";
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 0;
	height: 35px;
	width: 3px;
  }
  
  .card .card {
	border: 1px solid $border;
  }
  
  @media print {
	.card {
	  box-shadow: none;
	  border: none;
	}
  }
  
  .card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;
  
	+ .card-body {
	  border-top: 1px solid $border;
	}
  }
  
  .tilebox-one i {
	font-size: 62px;
  }
  
  .card-body > :last-child {
	margin-bottom: 0;
  }
  
  @media print {
	.card-body {
	  padding: 0;
	}
  }
  
  .card-body-scrollable {
	overflow: auto;
  }
  
  .card-footer, .card-bottom {
	padding: 1rem 1.5rem;
	background: none;
  }
  
  .card-footer {
	border-top: 1px solid $border;
	color: #6e7687;
  }
  
  .card-header {
	background: none;
	padding: 0.5rem 1.5rem;
	display: -ms-flexbox;
	display: flex;
	min-height: 3.5rem;
	-ms-flex-align: center;
	align-items: center;
  
	.card-title {
	  margin-bottom: 0;
	}
  
	&.border-0 + .card-body {
	  padding-top: 0;
	}
  }
  
  @media print {
	.card-header {
	  display: none;
	}
  }
  
  .card-img-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
  }
  
  .card-title {
	font-size: 1rem;
	line-height: 1.2;
	font-weight: 500;
  }
  
  .reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
  }
  
  .card-title {
	a {
	  color: inherit;
	}
  
	&:only-child {
	  margin-bottom: 0;
	}
  
	small {
	  color: #9aa0ac;
	  font-size: 0.875rem;
	  display: block;
	  margin: -.75rem 0 1rem;
	  line-height: 1.1;
	  font-weight: 400;
	}
  }
  
  .card-subtitle {
	color: #9aa0ac;
	font-size: 0.875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
  }
  
  .card-table {
	margin-bottom: 0;
  
	tr {
	  &:first-child {
		td, th {
		  border-top: 0;
		}
	  }
  
	  td:first-child, th:first-child {
		padding-left: 1.5rem;
	  }
  
	  td:last-child, th:last-child {
		padding-right: 1.5rem;
	  }
	}
  }
  
  .card-body + .card-table {
	border-top: 1px solid $border;
  }
  
  .card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
  }
  
  .card-profile-img {
	max-width: 6rem;
	margin-bottom: 1rem;
	border: 3px solid $white;
	border-radius: 100%;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  
  .card-link + .card-link {
	margin-left: 1rem;
  }
  
  .card-body + .card-list-group {
	border-top: 1px solid $border;
  }
  
  .card-list-group .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
  
	&:last-child {
	  border-bottom: 0;
	}
  
	&:first-child {
	  border-top: 0;
	}
  }
  
  .card-header-tabs {
	margin: -1.25rem 0;
	border-bottom: 0;
	line-height: 2rem;
  
	.nav-item {
	  margin-bottom: 1px;
	}
  }
  
  .card-header-pills {
	margin: -.75rem 0;
  }
  
  .card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
  }
  
  .card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
  }
  
  .card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
  
	i {
	  vertical-align: middle;
	  font-size: 30px;
	}
  }
  
  .card-chart-bg {
	height: 4rem;
	margin-top: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
  }
  
  .card-options {
	margin-right: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-left: -.5rem;
	color: #9aa0ac;
	-ms-flex-item-align: center;
	align-self: center;
  
	a:not(.btn) {
	  margin-right: .5rem;
	  color: #90a1bb;
	  display: inline-block;
	  min-width: 1rem;
  
	  &:hover {
		text-decoration: none;
		color: #6e7687;
	  }
  
	  i {
		font-size: 1rem;
		vertical-align: middle;
	  }
	}
  
	.dropdown-toggle:after {
	  display: none;
	}
  }
  
  .card-img-absolute {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	margin-right: -45px;
  }
  
  .card-image {
	position: absolute;
	top: 0;
	height: 100%;
	margin-left: -126px;
  }
  
  /*Card options*/
  
  .card-collapsed {
	> :not(.card-header):not(.card-status) {
	  display: none;
	}
  
	.card-options-collapse i:before {
	  content: '\e92d';
	}
  }
  
  .card-fullscreen {
	.card-options-fullscreen i:before {
	  content: '\e992';
	}
  
	.card-options-remove {
	  display: none;
	}
  }
  
  /*Card maps*/
  
  .card-map {
	height: 15rem;
	background: #e9ecef;
  }
  
  .card-map-placeholder {
	background: no-repeat center;
  }
  
  /**Card tabs*/
  
  .card-tabs {
	display: -ms-flexbox;
	display: flex;
  }
  
  .card-tabs-bottom .card-tabs-item {
	border: 0;
  
	&.active {
	  border-top-color: $white;
	}
  }
  
  .card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
  }
  
  a.card-tabs-item {
	background: #fafbfc;
  
	&:hover {
	  text-decoration: none;
	  color: inherit;
	}
  
	&:focus {
	  z-index: 1;
	}
  
	&.active {
	  background: $white;
	  border-bottom-color: $white;
	}
  }
  
  /**Card status*/
  
  .card-status {
	position: absolute;
	top: -1px;
	left: 0px;
	right: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
  }
  
  .card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 4px 0 0 3px;
  }
  
  /**Card icon*/
  
  .card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
  }
  
  /**Card fullscreen*/
  
  .card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
  }
  
  /**Card alert*/
  
  .card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
  }
  
  .card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: #e5e6f1;
  }