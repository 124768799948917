/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: $color;
	background-color: rgba(236, 238, 249, 0.9);
	border-radius: 4px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
  
	&:hover {
	  background-color: #eef0fa;
	  color: inherit;
	}
  }
  
  .tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background: rgba(0, 0, 0, 0.06);
	margin: 0 .5rem 0 -.5rem;
	text-align: center;
	min-width: 1.5rem;
  
	&:last-child {
	  border-top-left-radius: 4px;
	  border-bottom-left-radius: 4px;
	}
  
	i {
	  vertical-align: middle;
	  margin: 0 -.25rem;
	}
  }
  
  a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
  
	&:hover {
	  background: rgba(0, 0, 0, 0.16);
	  color: inherit;
	}
  }
  
  .tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 4px 0 0 3px;
	margin: 0 -.5rem 0 .5rem;
  }
  
  .tag-blue {
	background-color: #467fcf;
	color: $white;
  }
  
  .tag-indigo {
	background-color: $indigo;
	color: $white;
  }
  
  .tag-purple {
	background-color: #867efc;
	color: $white;
  }
  
  .tag-pink {
	background-color: $pink;
	color: $white;
  }
  
  .tag-red {
	background-color: #c21a1a;
	color: $white;
  }
  
  .tag-orange {
	background-color: $orange;
	color: $white;
  }
  
  .tag-yellow {
	background-color: #ecb403;
	color: $white;
  }
  
  .tag-green {
	background-color: #4ecc48;
	color: $white;
  }
  
  .tag-teal {
	background-color: $teal;
	color: $white;
  }
  
  .tag-cyan {
	background-color: #17a2b8;
	color: $white;
  }
  
  .tag-white {
	background-color: $white;
	color: $white;
  }
  
  .tag-gray {
	background-color: $gray;
	color: $white;
  }
  
  .tag-gray-dark {
	background-color: $dark;
	color: $white;
  }
  
  .tag-azure {
	background-color: #45aaf2;
	color: $white;
  }
  
  .tag-lime {
	background-color: #7bd235;
	color: $white;
  }
  
  .tag-primary {
	background-color: #467fcf;
	color: $white;
  }
  
  .tag-secondary {
	background-color: $gray;
	color: $white;
  }
  
  .tag-success {
	background-color: #4ecc48;
	color: $white;
  }
  
  .tag-info {
	background-color: #45aaf2;
	color: $white;
  }
  
  .tag-warning {
	background-color: #ecb403;
	color: $white;
  }
  
  .tag-danger {
	background-color: #c21a1a;
	color: $white;
  }
  
  .tag-light {
	background-color: #e7e9f5;
	color: $white;
  }
  
  .tag-dark {
	background-color: $dark;
	color: $white;
  }
  
  .tag-rounded {
	border-radius: 50px;
  
	.tag-avatar {
	  border-radius: 50px;
	}
  }
  
  .tags {
	margin-bottom: -.5rem;
	font-size: 0;
  
	> .tag {
	  margin-bottom: .5rem;
  
	  &:not(:last-child) {
		margin-left: .5rem;
	  }
	}
  }