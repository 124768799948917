.example .pagination {
	margin-bottom: 0;
  }
  
  .pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 4px;
  }
  
  .page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid $border;
  }
  
  .page-item a {
	color: $color;
  }
  
  .page-link {
	&:hover {
	  z-index: 2;
	  text-decoration: none;
	  background-color: rgba(70, 127, 207, 0.06);
	}
  
	&:focus {
	  z-index: 2;
	  outline: 0;
	  box-shadow: none;
	}
  
	&:not(:disabled):not(.disabled) {
	  cursor: pointer;
	}
  }
  
  .page-item {
	&:first-child .page-link {
	  margin-left: 0;
	  border-top-right-radius: 4px;
	  border-bottom-right-radius: 4px;
	  border-top-left-radius: 0px;
	  border-bottom-left-radius: 0px;
	}
  
	&:last-child .page-link {
	  border-top-left-radius: 4px;
	  border-bottom-left-radius: 4px;
	  border-top-right-radius: 0px;
	  border-bottom-right-radius: 0px;
	}
  
	&.active .page-link {
	  z-index: 0;
	  color: $white;
	  border: 0;
	}
  
	&.disabled .page-link {
	  color: #ced4da;
	  pointer-events: none;
	  cursor: auto;
	  background-color: $white;
	  border-color: $border;
	}
  }
  
  .pagination-lg {
	.page-link {
	  padding: 0.75rem 1.5rem;
	  font-size: 1.125rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	  }
	}
  }
  
  .pagination-sm {
	.page-link {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.875rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	  }
	}
  }
  
  .pagination-simple .page-item {
	.page-link {
	  background: none;
	  border: none;
	}
  
	&.active .page-link {
	  color: #455b7e;
	  font-weight: 700;
	}
  }
  
  .pagination-pager {
	.page-prev {
	  margin-right: auto;
	}
  
	.page-next {
	  margin-left: auto;
	}
  }
  
  .page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #6e7687;
  }.example .pagination {
  margin-bottom: 0;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: $white;
  border: 1px solid $border;
}

.page-item a {
  color: $color;
}

.page-link {
  &:hover {
    z-index: 2;
    text-decoration: none;
    background-color: rgba(70, 127, 207, 0.06);
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item {
  &:first-child .page-link {
    margin-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:last-child .page-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.active .page-link {
    z-index: 0;
    color: $white;
    border: 0;
  }

  &.disabled .page-link {
    color: #ced4da;
    pointer-events: none;
    cursor: auto;
    background-color: $white;
    border-color: $border;
  }
}

.pagination-lg {
  .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child .page-link {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.pagination-sm {
  .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child .page-link {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.pagination-simple .page-item {
  .page-link {
    background: none;
    border: none;
  }

  &.active .page-link {
    color: #455b7e;
    font-weight: 700;
  }
}

.pagination-pager {
  .page-prev {
    margin-right: auto;
  }

  .page-next {
    margin-left: auto;
  }
}

.page-total-text {
  margin-right: 1rem;
  -ms-flex-item-align: center;
  align-self: center;
  color: #6e7687;
}