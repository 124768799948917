/* ------ Table Of Contents
	* Bootstrap Framework
	* Alignments
	* Widths, Margins & Paddings
	* Alignments & values
	* Tables
	* Forms
	* Buttons
	* Drop Downs
	* Loader
	* Custom control
	* Navigation
	* Card
	* Email services
	* Range slider
	* Pricing tables
	* Pricing Styles
	* Tooptip &popover
	* Typography
	* Charts styles
	* Media object
	* Breadcrumb
	* Accordion
	* List Of Dropdwons
	* Badges
	* Jumbotron
	* Alerts
	* Progress
	* Lists
	* Modal
	* Carousel
	* Headers
	* Background colors
	* Btn styles
	* Panel
	* Thumbnails
	* Default Card Values
	* Avatars
	* progress sizes
	* Email
	* Loaders
	* Profile
	* Icon input
	* Tags
	* Date Picker
	* Back to Top
	* Time line
	* Icons List
	* selectize
	* Gallery
	* Feather icons
	
*/

* {
	box-sizing: border-box;
  
	&::before, &::after {
	  box-sizing: border-box;
	}
  }
  
  html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
  }
  
  @-ms-viewport {
	width: device-width;
  }
  
  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
  }
  
  body {
	margin: 0;
	font-family: 'IBM Plex Sans', sans-serif;
	color: $color;
	text-align: right;
	background-color: $background;
	font-size: .8125rem;
	font-weight: 400;
	line-height: 1.5;
  }
  
  [tabindex="-1"]:focus {
	outline: 0 !important;
  }
  
  hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.66em;
  }
  
  p {
	margin-top: 0;
	margin-bottom: 1rem;
  }
  
  abbr {
	&[title], &[data-original-title] {
	  text-decoration: underline;
	  -webkit-text-decoration: underline dotted;
	  text-decoration: underline dotted;
	  cursor: help;
	  border-bottom: 0;
	}
  }
  
  address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
  }
  
  ol, ul, dl {
	margin-top: 0;
	margin-bottom: 1rem;
  }
  
  ol ol, ul ul, ol ul, ul ol {
	margin-bottom: 0;
  }
  
  dt {
	font-weight: 700;
  }
  
  dd {
	margin-bottom: .5rem;
	margin-left: 0;
  }
  
  blockquote {
	margin: 0 0 1rem;
  }
  
  dfn {
	font-style: italic;
  }
  
  b, strong {
	font-weight: bolder;
  }
  
  small {
	font-size: 80%;
  }
  
  sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
  }
  
  sub {
	bottom: -.25em;
  }
  
  sup {
	top: -.5em;
  }
  
  .number-font {
	font-family: 'Rubik', sans-serif;
	font-size: 2rem;
	font-weight: 400;
  }
  
  .number-font1 {
	font-family: 'Rubik', sans-serif !important;
	font-weight: 400;
  }
  
  /*PMboYSIqMee+p4uAjskftSrErYaF9PDNDn+EGSzR9N2BspYI8=
  feCz66HNQhyoUIndT6pXQpWta+PA3e1h3yExMyH1EsOo6f8PXnNPyHGLRfchOSF9WSX7exs=*/
  
  a {
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
  
	&:hover {
	  text-decoration: underline;
	}
  
	&:not([href]):not([tabindex]) {
	  color: inherit;
	  text-decoration: none;
  
	  &:hover {
		color: inherit;
		text-decoration: none;
	  }
  
	  &:focus {
		color: inherit;
		text-decoration: none;
		outline: 0;
	  }
	}
  }
  
  pre, code, kbd, samp {
	font-family: monospace, monospace;
	font-size: 1em;
  }
  
  pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
  }
  
  figure {
	margin: 0 0 1rem;
  }
  
  .image {
	position: relative;
  }
  
  img {
	vertical-align: middle;
	border-style: none;
  }
  
  .img-1 {
	vertical-align: middle;
	position: absolute;
	text-align: center;
	left: 0;
	right: 0;
	bottom: -62px;
  
	img {
	  border-radius: 100%;
	  border: 6px solid rgba(225, 225, 225, 0.5);
	}
  }
  
  .img-2 img {
	border-radius: 5px;
  }
  
  svg:not(:root) {
	overflow: hidden;
  }
  
  table {
	border-collapse: collapse;
  }
  
  caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #505662;
	text-align: left;
	caption-side: bottom;
  }
  
  th {
	text-align: inherit;
  }
  
  label {
	display: inline-block;
	margin-bottom: 0.5rem;
  }
  
  button {
	border-radius: 0;
  
	&:focus {
	  outline: 1px dotted;
	  outline: 5px auto -webkit-focus-ring-color;
	}
  }
  
  input, button, select, optgroup, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
  }
  
  button, input {
	overflow: visible;
  }
  
  button, select {
	text-transform: none;
  }
  
  button, html [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
  }
  
  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
  }
  
  input {
	&[type="radio"], &[type="checkbox"] {
	  box-sizing: border-box;
	  padding: 0;
	}
  
	&[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
	  -webkit-appearance: listbox;
	}
  }
  
  textarea {
	overflow: auto;
	resize: vertical;
  }
  
  fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
  }
  
  legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
  }
  
  progress {
	vertical-align: baseline;
  }
  
  [type="number"] {
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
	  height: auto;
	}
  }
  
  [type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
  
	&::-webkit-search-cancel-button, &::-webkit-search-decoration {
	  -webkit-appearance: none;
	}
  }
  
  ::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
  }
  
  output {
	display: inline-block;
  }
  
  summary {
	display: list-item;
	cursor: pointer;
  }
  
  template {
	display: none;
  }
  
  [hidden] {
	display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.66em;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: $color;
  }
  
  h1, .h1 {
	font-size: 2rem;
  }
  
  h2, .h2 {
	font-size: 1.75rem;
  }
  
  h3, .h3 {
	font-size: 1.5rem;
  }
  
  h4, .h4 {
	font-size: 1.125rem;
  }
  
  h5, .h5 {
	font-size: 1rem;
  }
  
  h6, .h6 {
	font-size: 0.875rem;
  }
  
  .lead {
	font-size: 1.171875rem;
	font-weight: 300;
  }
  
  .display-1 {
	font-size: 10.5rem;
	font-weight: 500;
	line-height: 1.1;
  }
  
  .display-2 {
	font-size: 4rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-3 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-4 {
	font-size: 3rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-5 {
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  .display-6 {
	font-size: 2.3rem;
	font-weight: 300;
	line-height: 1.1;
  }
  
  hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid $border;
  }
  
  small, .small {
	font-size: 87.5%;
	font-weight: 400;
  }
  
  mark, .mark {
	padding: 0.2em;
	background-color: #fcf8e3;
  }
  
  .list-unstyled {
	padding-left: 0;
	list-style: none;
  
	li {
	  border-bottom: 1px solid $border;
	}
  }
  
  .nav-sidebar .list-unstyled li:last-child {
	border-bottom: 0px;
  }
  
  .list-inline {
	padding-left: 0;
	list-style: none;
  }
  
  .list-inline-item {
	display: inline-block;
  
	&:not(:last-child) {
	  margin-right: 0.5rem;
	}
  }
  
  .initialism {
	font-size: 90%;
	text-transform: uppercase;
  }
  
  .blockquote {
	margin-bottom: 1rem;
	font-size: 1.171875rem;
  }
  
  .blockquote-footer {
	display: block;
	font-size: 80%;
	color: $gray;
  
	&::before {
	  content: "\2014 \00A0";
	}
  }
  
  .img-fluid {
	max-width: 100%;
	height: auto;
  }
  
  .item-card img:hover {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
  }
  
  .img-thumbnail {
	padding: 0.25rem;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 4px;
	max-width: 100%;
	height: auto;
  }
  
  .figure {
	display: inline-block;
  }
  
  .figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
  }
  
  .figure-caption {
	font-size: 90%;
	color: $gray;
  }
  
  .fs-10 {
	font-size: 10px !important;
  }
  
  .fs-11 {
	font-size: 11px !important;
  }
  
  .fs-12 {
	font-size: 12px !important;
  }
  
  .fs-13 {
	font-size: 13px !important;
  }
  
  .fs-14 {
	font-size: 14px !important;
  }
  
  .fs-15 {
	font-size: 15px !important;
  }
  
  .fs-16 {
	font-size: 16px !important;
  }
  
  .fs-17 {
	font-size: 17px;
  }
  
  .fs-20 {
	font-size: 20px !important;
  }
  
  .fs-23 {
	font-size: 23px !important;
  }
  
  .fs-25 {
	font-size: 25px !important;
  }
  
  .fs-30 {
	font-size: 30px !important;
  }
  
  .fs-40 {
	font-size: 40px;
  }
  
  .fs-50 {
	font-size: 50px;
  }
  
  code, kbd, pre, samp {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  code {
	font-size: 85%;
	color: inherit;
	word-break: break-word;
  }
  
  a > code {
	color: inherit;
  }
  
  kbd {
	padding: 0.2rem 0.4rem;
	font-size: 85%;
	color: $white;
	background-color: $dark;
	border-radius: 4px;
  
	kbd {
	  padding: 0;
	  font-size: 100%;
	  font-weight: 700;
	}
  }
  
  pre {
	display: block;
	font-size: 85%;
	color: #212529;
  
	code {
	  font-size: inherit;
	  color: inherit;
	  word-break: normal;
	}
  }
  
  .pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
  }
  
  .container {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
  }
  
  @media (max-width: 576px) {
	.container {
	  padding-right: 0.75rem;
	  padding-left: 0.75rem;
	}
  }
  
  @media (max-width: 1279px) {
	.wideget-user-info .wideget-user-warap {
	  margin-top: 2rem !important;
	}
  }
  
  @media (max-width: 360px) {
	.wideget-user-desc {
	  .wideget-user-img {
		width: 130px;
		height: 130px;
	  }
  
	  .user-wrap {
		margin-top: 0.7rem !important;
		margin-left: 13px;
	  }
	}
  
	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
	  margin-left: 5rem !important;
	}
  }
  
  @media (min-width: 576px) {
	.container {
	  max-width: 540px;
	}
  }
  
  @media (min-width: 768px) {
	.container {
	  max-width: 720px;
	}
  }
  
  @media (min-width: 992px) {
	.container {
	  max-width: 960px;
	}
  }
  
  @media (min-width: 1280px) {
	.container {
	  max-width: 1200px;
	}
  }
  
  .container-fluid {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
  }
  
  .row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -0.25rem;
	margin-left: -0.25rem;
  }
  
  .no-gutters {
	margin-right: 0;
	margin-left: 0;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
  }
  
  .col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
  }
  
  .col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: none;
  }
  
  .col-1 {
	-ms-flex: 0 0 8.33333333%;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
  }
  
  .col-2 {
	-ms-flex: 0 0 16.66666667%;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
  }
  
  .col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
  }
  
  .col-4 {
	-ms-flex: 0 0 33.33333333%;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
  }
  
  .col-5 {
	-ms-flex: 0 0 41.66666667%;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
  }
  
  .col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
  }
  
  .col-7 {
	-ms-flex: 0 0 58.33333333%;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
  }
  
  .col-8 {
	-ms-flex: 0 0 66.66666667%;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
  }
  
  .col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
  }
  
  .col-10 {
	-ms-flex: 0 0 83.33333333%;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
  }
  
  .col-11 {
	-ms-flex: 0 0 91.66666667%;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
  }
  
  .col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
  }
  
  .order-first {
	-ms-flex-order: -1;
	order: -1;
  }
  
  .order-last {
	-ms-flex-order: 13;
	order: 13;
  }
  
  .order-0 {
	-ms-flex-order: 0;
	order: 0;
  }
  
  .order-1 {
	-ms-flex-order: 1;
	order: 1;
  }
  
  .order-2 {
	-ms-flex-order: 2;
	order: 2;
  }
  
  .order-3 {
	-ms-flex-order: 3;
	order: 3;
  }
  
  .order-4 {
	-ms-flex-order: 4;
	order: 4;
  }
  
  .order-5 {
	-ms-flex-order: 5;
	order: 5;
  }
  
  .order-6 {
	-ms-flex-order: 6;
	order: 6;
  }
  
  .order-7 {
	-ms-flex-order: 7;
	order: 7;
  }
  
  .order-8 {
	-ms-flex-order: 8;
	order: 8;
  }
  
  .order-9 {
	-ms-flex-order: 9;
	order: 9;
  }
  
  .order-10 {
	-ms-flex-order: 10;
	order: 10;
  }
  
  .order-11 {
	-ms-flex-order: 11;
	order: 11;
  }
  
  .order-12 {
	-ms-flex-order: 12;
	order: 12;
  }
  
  .offset-1 {
	margin-left: 8.33333333%;
  }
  
  .offset-2 {
	margin-left: 16.66666667%;
  }
  
  .offset-3 {
	margin-left: 25%;
  }
  
  .offset-4 {
	margin-left: 33.33333333%;
  }
  
  .offset-5 {
	margin-left: 41.66666667%;
  }
  
  .offset-6 {
	margin-left: 50%;
  }
  
  .offset-7 {
	margin-left: 58.33333333%;
  }
  
  .offset-8 {
	margin-left: 66.66666667%;
  }
  
  .offset-9 {
	margin-left: 75%;
  }
  
  .offset-10 {
	margin-left: 83.33333333%;
  }
  
  .offset-11 {
	margin-left: 91.66666667%;
  }
  
  @media (min-width: 576px) {
	.col-sm {
	  -ms-flex-preferred-size: 0;
	  flex-basis: 0;
	  -ms-flex-positive: 1;
	  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-sm-auto {
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-sm-1 {
	  -ms-flex: 0 0 8.33333333%;
	  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-sm-2 {
	  -ms-flex: 0 0 16.66666667%;
	  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-sm-3 {
	  -ms-flex: 0 0 25%;
	  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-sm-4 {
	  -ms-flex: 0 0 33.33333333%;
	  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-sm-5 {
	  -ms-flex: 0 0 41.66666667%;
	  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-sm-6 {
	  -ms-flex: 0 0 50%;
	  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-sm-7 {
	  -ms-flex: 0 0 58.33333333%;
	  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-sm-8 {
	  -ms-flex: 0 0 66.66666667%;
	  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-sm-9 {
	  -ms-flex: 0 0 75%;
	  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-sm-10 {
	  -ms-flex: 0 0 83.33333333%;
	  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-sm-11 {
	  -ms-flex: 0 0 91.66666667%;
	  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-sm-12 {
	  -ms-flex: 0 0 100%;
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-sm-first {
	  -ms-flex-order: -1;
	  order: -1;
	}
  
	.order-sm-last {
	  -ms-flex-order: 13;
	  order: 13;
	}
  
	.order-sm-0 {
	  -ms-flex-order: 0;
	  order: 0;
	}
  
	.order-sm-1 {
	  -ms-flex-order: 1;
	  order: 1;
	}
  
	.order-sm-2 {
	  -ms-flex-order: 2;
	  order: 2;
	}
  
	.order-sm-3 {
	  -ms-flex-order: 3;
	  order: 3;
	}
  
	.order-sm-4 {
	  -ms-flex-order: 4;
	  order: 4;
	}
  
	.order-sm-5 {
	  -ms-flex-order: 5;
	  order: 5;
	}
  
	.order-sm-6 {
	  -ms-flex-order: 6;
	  order: 6;
	}
  
	.order-sm-7 {
	  -ms-flex-order: 7;
	  order: 7;
	}
  
	.order-sm-8 {
	  -ms-flex-order: 8;
	  order: 8;
	}
  
	.order-sm-9 {
	  -ms-flex-order: 9;
	  order: 9;
	}
  
	.order-sm-10 {
	  -ms-flex-order: 10;
	  order: 10;
	}
  
	.order-sm-11 {
	  -ms-flex-order: 11;
	  order: 11;
	}
  
	.order-sm-12 {
	  -ms-flex-order: 12;
	  order: 12;
	}
  
	.offset-sm-0 {
	  margin-left: 0;
	}
  
	.offset-sm-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-sm-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-sm-3 {
	  margin-left: 25%;
	}
  
	.offset-sm-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-sm-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-sm-6 {
	  margin-left: 50%;
	}
  
	.offset-sm-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-sm-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-sm-9 {
	  margin-left: 75%;
	}
  
	.offset-sm-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-sm-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 768px) {
	.col-md {
	  -ms-flex-preferred-size: 0;
	  flex-basis: 0;
	  -ms-flex-positive: 1;
	  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-md-auto {
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-md-1 {
	  -ms-flex: 0 0 8.33333333%;
	  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-md-2 {
	  -ms-flex: 0 0 16.66666667%;
	  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-md-3 {
	  -ms-flex: 0 0 25%;
	  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-md-4 {
	  -ms-flex: 0 0 33.33333333%;
	  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-md-5 {
	  -ms-flex: 0 0 41.66666667%;
	  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-md-6 {
	  -ms-flex: 0 0 50%;
	  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-md-7 {
	  -ms-flex: 0 0 58.33333333%;
	  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-md-8 {
	  -ms-flex: 0 0 66.66666667%;
	  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-md-9 {
	  -ms-flex: 0 0 75%;
	  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-md-10 {
	  -ms-flex: 0 0 83.33333333%;
	  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-md-11 {
	  -ms-flex: 0 0 91.66666667%;
	  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-md-12 {
	  -ms-flex: 0 0 100%;
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-md-first {
	  -ms-flex-order: -1;
	  order: -1;
	}
  
	.order-md-last {
	  -ms-flex-order: 13;
	  order: 13;
	}
  
	.order-md-0 {
	  -ms-flex-order: 0;
	  order: 0;
	}
  
	.order-md-1 {
	  -ms-flex-order: 1;
	  order: 1;
	}
  
	.order-md-2 {
	  -ms-flex-order: 2;
	  order: 2;
	}
  
	.order-md-3 {
	  -ms-flex-order: 3;
	  order: 3;
	}
  
	.order-md-4 {
	  -ms-flex-order: 4;
	  order: 4;
	}
  
	.order-md-5 {
	  -ms-flex-order: 5;
	  order: 5;
	}
  
	.order-md-6 {
	  -ms-flex-order: 6;
	  order: 6;
	}
  
	.order-md-7 {
	  -ms-flex-order: 7;
	  order: 7;
	}
  
	.order-md-8 {
	  -ms-flex-order: 8;
	  order: 8;
	}
  
	.order-md-9 {
	  -ms-flex-order: 9;
	  order: 9;
	}
  
	.order-md-10 {
	  -ms-flex-order: 10;
	  order: 10;
	}
  
	.order-md-11 {
	  -ms-flex-order: 11;
	  order: 11;
	}
  
	.order-md-12 {
	  -ms-flex-order: 12;
	  order: 12;
	}
  
	.offset-md-0 {
	  margin-left: 0;
	}
  
	.offset-md-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-md-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-md-3 {
	  margin-left: 25%;
	}
  
	.offset-md-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-md-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-md-6 {
	  margin-left: 50%;
	}
  
	.offset-md-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-md-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-md-9 {
	  margin-left: 75%;
	}
  
	.offset-md-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-md-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 992px) {
	.col-lg {
	  -ms-flex-preferred-size: 0;
	  flex-basis: 0;
	  -ms-flex-positive: 1;
	  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-lg-auto {
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-lg-1 {
	  -ms-flex: 0 0 8.33333333%;
	  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-lg-2 {
	  -ms-flex: 0 0 16.66666667%;
	  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-lg-3 {
	  -ms-flex: 0 0 25%;
	  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-lg-4 {
	  -ms-flex: 0 0 33.33333333%;
	  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-lg-5 {
	  -ms-flex: 0 0 41.66666667%;
	  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-lg-6 {
	  -ms-flex: 0 0 50%;
	  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-lg-7 {
	  -ms-flex: 0 0 58.33333333%;
	  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-lg-8 {
	  -ms-flex: 0 0 66.66666667%;
	  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-lg-9 {
	  -ms-flex: 0 0 75%;
	  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-lg-10 {
	  -ms-flex: 0 0 83.33333333%;
	  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-lg-11 {
	  -ms-flex: 0 0 91.66666667%;
	  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-lg-12 {
	  -ms-flex: 0 0 100%;
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-lg-first {
	  -ms-flex-order: -1;
	  order: -1;
	}
  
	.order-lg-last {
	  -ms-flex-order: 13;
	  order: 13;
	}
  
	.order-lg-0 {
	  -ms-flex-order: 0;
	  order: 0;
	}
  
	.order-lg-1 {
	  -ms-flex-order: 1;
	  order: 1;
	}
  
	.order-lg-2 {
	  -ms-flex-order: 2;
	  order: 2;
	}
  
	.order-lg-3 {
	  -ms-flex-order: 3;
	  order: 3;
	}
  
	.order-lg-4 {
	  -ms-flex-order: 4;
	  order: 4;
	}
  
	.order-lg-5 {
	  -ms-flex-order: 5;
	  order: 5;
	}
  
	.order-lg-6 {
	  -ms-flex-order: 6;
	  order: 6;
	}
  
	.order-lg-7 {
	  -ms-flex-order: 7;
	  order: 7;
	}
  
	.order-lg-8 {
	  -ms-flex-order: 8;
	  order: 8;
	}
  
	.order-lg-9 {
	  -ms-flex-order: 9;
	  order: 9;
	}
  
	.order-lg-10 {
	  -ms-flex-order: 10;
	  order: 10;
	}
  
	.order-lg-11 {
	  -ms-flex-order: 11;
	  order: 11;
	}
  
	.order-lg-12 {
	  -ms-flex-order: 12;
	  order: 12;
	}
  
	.offset-lg-0 {
	  margin-left: 0;
	}
  
	.offset-lg-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-lg-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-lg-3 {
	  margin-left: 25%;
	}
  
	.offset-lg-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-lg-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-lg-6 {
	  margin-left: 50%;
	}
  
	.offset-lg-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-lg-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-lg-9 {
	  margin-left: 75%;
	}
  
	.offset-lg-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-lg-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  @media (min-width: 1280px) {
	.col-xl {
	  -ms-flex-preferred-size: 0;
	  flex-basis: 0;
	  -ms-flex-positive: 1;
	  flex-grow: 1;
	  max-width: 100%;
	}
  
	.col-xl-auto {
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  width: auto;
	  max-width: none;
	}
  
	.col-xl-1 {
	  -ms-flex: 0 0 8.33333333%;
	  flex: 0 0 8.33333333%;
	  max-width: 8.33333333%;
	}
  
	.col-xl-2 {
	  -ms-flex: 0 0 16.66666667%;
	  flex: 0 0 16.66666667%;
	  max-width: 16.66666667%;
	}
  
	.col-xl-3 {
	  -ms-flex: 0 0 25%;
	  flex: 0 0 25%;
	  max-width: 25%;
	}
  
	.col-xl-4 {
	  -ms-flex: 0 0 33.33333333%;
	  flex: 0 0 33.33333333%;
	  max-width: 33.33333333%;
	}
  
	.col-xl-5 {
	  -ms-flex: 0 0 41.66666667%;
	  flex: 0 0 41.66666667%;
	  max-width: 41.66666667%;
	}
  
	.col-xl-6 {
	  -ms-flex: 0 0 50%;
	  flex: 0 0 50%;
	  max-width: 50%;
	}
  
	.col-xl-7 {
	  -ms-flex: 0 0 58.33333333%;
	  flex: 0 0 58.33333333%;
	  max-width: 58.33333333%;
	}
  
	.col-xl-8 {
	  -ms-flex: 0 0 66.66666667%;
	  flex: 0 0 66.66666667%;
	  max-width: 66.66666667%;
	}
  
	.col-xl-9 {
	  -ms-flex: 0 0 75%;
	  flex: 0 0 75%;
	  max-width: 75%;
	}
  
	.col-xl-10 {
	  -ms-flex: 0 0 83.33333333%;
	  flex: 0 0 83.33333333%;
	  max-width: 83.33333333%;
	}
  
	.col-xl-11 {
	  -ms-flex: 0 0 91.66666667%;
	  flex: 0 0 91.66666667%;
	  max-width: 91.66666667%;
	}
  
	.col-xl-12 {
	  -ms-flex: 0 0 100%;
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  
	.order-xl-first {
	  -ms-flex-order: -1;
	  order: -1;
	}
  
	.order-xl-last {
	  -ms-flex-order: 13;
	  order: 13;
	}
  
	.order-xl-0 {
	  -ms-flex-order: 0;
	  order: 0;
	}
  
	.order-xl-1 {
	  -ms-flex-order: 1;
	  order: 1;
	}
  
	.order-xl-2 {
	  -ms-flex-order: 2;
	  order: 2;
	}
  
	.order-xl-3 {
	  -ms-flex-order: 3;
	  order: 3;
	}
  
	.order-xl-4 {
	  -ms-flex-order: 4;
	  order: 4;
	}
  
	.order-xl-5 {
	  -ms-flex-order: 5;
	  order: 5;
	}
  
	.order-xl-6 {
	  -ms-flex-order: 6;
	  order: 6;
	}
  
	.order-xl-7 {
	  -ms-flex-order: 7;
	  order: 7;
	}
  
	.order-xl-8 {
	  -ms-flex-order: 8;
	  order: 8;
	}
  
	.order-xl-9 {
	  -ms-flex-order: 9;
	  order: 9;
	}
  
	.order-xl-10 {
	  -ms-flex-order: 10;
	  order: 10;
	}
  
	.order-xl-11 {
	  -ms-flex-order: 11;
	  order: 11;
	}
  
	.order-xl-12 {
	  -ms-flex-order: 12;
	  order: 12;
	}
  
	.offset-xl-0 {
	  margin-left: 0;
	}
  
	.offset-xl-1 {
	  margin-left: 8.33333333%;
	}
  
	.offset-xl-2 {
	  margin-left: 16.66666667%;
	}
  
	.offset-xl-3 {
	  margin-left: 25%;
	}
  
	.offset-xl-4 {
	  margin-left: 33.33333333%;
	}
  
	.offset-xl-5 {
	  margin-left: 41.66666667%;
	}
  
	.offset-xl-6 {
	  margin-left: 50%;
	}
  
	.offset-xl-7 {
	  margin-left: 58.33333333%;
	}
  
	.offset-xl-8 {
	  margin-left: 66.66666667%;
	}
  
	.offset-xl-9 {
	  margin-left: 75%;
	}
  
	.offset-xl-10 {
	  margin-left: 83.33333333%;
	}
  
	.offset-xl-11 {
	  margin-left: 91.66666667%;
	}
  }
  
  /*------ Alignments -------*/
  
  .align-baseline {
	vertical-align: baseline !important;
  }
  
  .align-top {
	vertical-align: top !important;
  }
  
  .align-middle {
	vertical-align: middle !important;
  }
  
  .align-bottom {
	vertical-align: bottom !important;
  }
  
  .align-text-bottom {
	vertical-align: text-bottom !important;
  }
  
  .align-text-top {
	vertical-align: text-top !important;
  }
  
  .clearfix::after {
	display: block;
	clear: both;
	content: "";
  }
  
  .d-none {
	display: none !important;
  }
  
  .d-inline {
	display: inline !important;
  }
  
  .d-inline-block {
	display: inline-block !important;
  }
  
  .d-block {
	display: block !important;
  }
  
  .d-table {
	display: table !important;
  }
  
  .d-table-row {
	display: table-row !important;
  }
  
  .d-table-cell {
	display: table-cell !important;
  }
  
  .d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
  }
  
  .d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
	.d-sm-none {
	  display: none !important;
	}
  
	.d-sm-inline {
	  display: inline !important;
	}
  
	.d-sm-inline-block {
	  display: inline-block !important;
	}
  
	.d-sm-block {
	  display: block !important;
	}
  
	.d-sm-table {
	  display: table !important;
	}
  
	.d-sm-table-row {
	  display: table-row !important;
	}
  
	.d-sm-table-cell {
	  display: table-cell !important;
	}
  
	.d-sm-flex {
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-sm-inline-flex {
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 768px) {
	.d-md-none {
	  display: none !important;
	}
  
	.d-md-inline {
	  display: inline !important;
	}
  
	.d-md-inline-block {
	  display: inline-block !important;
	}
  
	.d-md-block {
	  display: block !important;
	}
  
	.d-md-table {
	  display: table !important;
	}
  
	.d-md-table-row {
	  display: table-row !important;
	}
  
	.d-md-table-cell {
	  display: table-cell !important;
	}
  
	.d-md-flex {
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-md-inline-flex {
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 992px) {
	.d-lg-none {
	  display: none !important;
	}
  
	.d-lg-inline {
	  display: inline !important;
	}
  
	.d-lg-inline-block {
	  display: inline-block !important;
	}
  
	.d-lg-block {
	  display: block !important;
	}
  
	.d-lg-table {
	  display: table !important;
	}
  
	.d-lg-table-row {
	  display: table-row !important;
	}
  
	.d-lg-table-cell {
	  display: table-cell !important;
	}
  
	.d-lg-flex {
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-lg-inline-flex {
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media (min-width: 1280px) {
	.d-xl-none {
	  display: none !important;
	}
  
	.d-xl-inline {
	  display: inline !important;
	}
  
	.d-xl-inline-block {
	  display: inline-block !important;
	}
  
	.d-xl-block {
	  display: block !important;
	}
  
	.d-xl-table {
	  display: table !important;
	}
  
	.d-xl-table-row {
	  display: table-row !important;
	}
  
	.d-xl-table-cell {
	  display: table-cell !important;
	}
  
	.d-xl-flex {
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-xl-inline-flex {
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  @media print {
	.d-print-none {
	  display: none !important;
	}
  
	.d-print-inline {
	  display: inline !important;
	}
  
	.d-print-inline-block {
	  display: inline-block !important;
	}
  
	.d-print-block {
	  display: block !important;
	}
  
	.d-print-table {
	  display: table !important;
	}
  
	.d-print-table-row {
	  display: table-row !important;
	}
  
	.d-print-table-cell {
	  display: table-cell !important;
	}
  
	.d-print-flex {
	  display: -ms-flexbox !important;
	  display: flex !important;
	}
  
	.d-print-inline-flex {
	  display: -ms-inline-flexbox !important;
	  display: inline-flex !important;
	}
  }
  
  .flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
  }
  
  .flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
  }
  
  .flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
  }
  
  .justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
  }
  
  .justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
  }
  
  .justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
  }
  
  .justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
  }
  
  .justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
  }
  
  .align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
  }
  
  .align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
  }
  
  .align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
  }
  
  .align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
  }
  
  .align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
  }
  
  .align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
  }
  
  .align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
  }
  
  .align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
  }
  
  .align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
  }
  
  .align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
  }
  
  .align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
  }
  
  .align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
  }
  
  .align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
  }
  
  .align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
  }
  
  .align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
  }
  
  .align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
  }
  
  .align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
	.flex-sm-row {
	  -ms-flex-direction: row !important;
	  flex-direction: row !important;
	}
  
	.flex-sm-column {
	  -ms-flex-direction: column !important;
	  flex-direction: column !important;
	}
  
	.flex-sm-row-reverse {
	  -ms-flex-direction: row-reverse !important;
	  flex-direction: row-reverse !important;
	}
  
	.flex-sm-column-reverse {
	  -ms-flex-direction: column-reverse !important;
	  flex-direction: column-reverse !important;
	}
  
	.flex-sm-wrap {
	  -ms-flex-wrap: wrap !important;
	  flex-wrap: wrap !important;
	}
  
	.flex-sm-nowrap {
	  -ms-flex-wrap: nowrap !important;
	  flex-wrap: nowrap !important;
	}
  
	.flex-sm-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-sm-fill {
	  -ms-flex: 1 1 auto !important;
	  flex: 1 1 auto !important;
	}
  
	.flex-sm-grow-0 {
	  -ms-flex-positive: 0 !important;
	  flex-grow: 0 !important;
	}
  
	.flex-sm-grow-1 {
	  -ms-flex-positive: 1 !important;
	  flex-grow: 1 !important;
	}
  
	.flex-sm-shrink-0 {
	  -ms-flex-negative: 0 !important;
	  flex-shrink: 0 !important;
	}
  
	.flex-sm-shrink-1 {
	  -ms-flex-negative: 1 !important;
	  flex-shrink: 1 !important;
	}
  
	.justify-content-sm-start {
	  -ms-flex-pack: start !important;
	  justify-content: flex-start !important;
	}
  
	.justify-content-sm-end {
	  -ms-flex-pack: end !important;
	  justify-content: flex-end !important;
	}
  
	.justify-content-sm-center {
	  -ms-flex-pack: center !important;
	  justify-content: center !important;
	}
  
	.justify-content-sm-between {
	  -ms-flex-pack: justify !important;
	  justify-content: space-between !important;
	}
  
	.justify-content-sm-around {
	  -ms-flex-pack: distribute !important;
	  justify-content: space-around !important;
	}
  
	.align-items-sm-start {
	  -ms-flex-align: start !important;
	  align-items: flex-start !important;
	}
  
	.align-items-sm-end {
	  -ms-flex-align: end !important;
	  align-items: flex-end !important;
	}
  
	.align-items-sm-center {
	  -ms-flex-align: center !important;
	  align-items: center !important;
	}
  
	.align-items-sm-baseline {
	  -ms-flex-align: baseline !important;
	  align-items: baseline !important;
	}
  
	.align-items-sm-stretch {
	  -ms-flex-align: stretch !important;
	  align-items: stretch !important;
	}
  
	.align-content-sm-start {
	  -ms-flex-line-pack: start !important;
	  align-content: flex-start !important;
	}
  
	.align-content-sm-end {
	  -ms-flex-line-pack: end !important;
	  align-content: flex-end !important;
	}
  
	.align-content-sm-center {
	  -ms-flex-line-pack: center !important;
	  align-content: center !important;
	}
  
	.align-content-sm-between {
	  -ms-flex-line-pack: justify !important;
	  align-content: space-between !important;
	}
  
	.align-content-sm-around {
	  -ms-flex-line-pack: distribute !important;
	  align-content: space-around !important;
	}
  
	.align-content-sm-stretch {
	  -ms-flex-line-pack: stretch !important;
	  align-content: stretch !important;
	}
  
	.align-self-sm-auto {
	  -ms-flex-item-align: auto !important;
	  align-self: auto !important;
	}
  
	.align-self-sm-start {
	  -ms-flex-item-align: start !important;
	  align-self: flex-start !important;
	}
  
	.align-self-sm-end {
	  -ms-flex-item-align: end !important;
	  align-self: flex-end !important;
	}
  
	.align-self-sm-center {
	  -ms-flex-item-align: center !important;
	  align-self: center !important;
	}
  
	.align-self-sm-baseline {
	  -ms-flex-item-align: baseline !important;
	  align-self: baseline !important;
	}
  
	.align-self-sm-stretch {
	  -ms-flex-item-align: stretch !important;
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 768px) {
	.flex-md-row {
	  -ms-flex-direction: row !important;
	  flex-direction: row !important;
	}
  
	.flex-md-column {
	  -ms-flex-direction: column !important;
	  flex-direction: column !important;
	}
  
	.flex-md-row-reverse {
	  -ms-flex-direction: row-reverse !important;
	  flex-direction: row-reverse !important;
	}
  
	.flex-md-column-reverse {
	  -ms-flex-direction: column-reverse !important;
	  flex-direction: column-reverse !important;
	}
  
	.flex-md-wrap {
	  -ms-flex-wrap: wrap !important;
	  flex-wrap: wrap !important;
	}
  
	.flex-md-nowrap {
	  -ms-flex-wrap: nowrap !important;
	  flex-wrap: nowrap !important;
	}
  
	.flex-md-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-md-fill {
	  -ms-flex: 1 1 auto !important;
	  flex: 1 1 auto !important;
	}
  
	.flex-md-grow-0 {
	  -ms-flex-positive: 0 !important;
	  flex-grow: 0 !important;
	}
  
	.flex-md-grow-1 {
	  -ms-flex-positive: 1 !important;
	  flex-grow: 1 !important;
	}
  
	.flex-md-shrink-0 {
	  -ms-flex-negative: 0 !important;
	  flex-shrink: 0 !important;
	}
  
	.flex-md-shrink-1 {
	  -ms-flex-negative: 1 !important;
	  flex-shrink: 1 !important;
	}
  
	.justify-content-md-start {
	  -ms-flex-pack: start !important;
	  justify-content: flex-start !important;
	}
  
	.justify-content-md-end {
	  -ms-flex-pack: end !important;
	  justify-content: flex-end !important;
	}
  
	.justify-content-md-center {
	  -ms-flex-pack: center !important;
	  justify-content: center !important;
	}
  
	.justify-content-md-between {
	  -ms-flex-pack: justify !important;
	  justify-content: space-between !important;
	}
  
	.justify-content-md-around {
	  -ms-flex-pack: distribute !important;
	  justify-content: space-around !important;
	}
  
	.align-items-md-start {
	  -ms-flex-align: start !important;
	  align-items: flex-start !important;
	}
  
	.align-items-md-end {
	  -ms-flex-align: end !important;
	  align-items: flex-end !important;
	}
  
	.align-items-md-center {
	  -ms-flex-align: center !important;
	  align-items: center !important;
	}
  
	.align-items-md-baseline {
	  -ms-flex-align: baseline !important;
	  align-items: baseline !important;
	}
  
	.align-items-md-stretch {
	  -ms-flex-align: stretch !important;
	  align-items: stretch !important;
	}
  
	.align-content-md-start {
	  -ms-flex-line-pack: start !important;
	  align-content: flex-start !important;
	}
  
	.align-content-md-end {
	  -ms-flex-line-pack: end !important;
	  align-content: flex-end !important;
	}
  
	.align-content-md-center {
	  -ms-flex-line-pack: center !important;
	  align-content: center !important;
	}
  
	.align-content-md-between {
	  -ms-flex-line-pack: justify !important;
	  align-content: space-between !important;
	}
  
	.align-content-md-around {
	  -ms-flex-line-pack: distribute !important;
	  align-content: space-around !important;
	}
  
	.align-content-md-stretch {
	  -ms-flex-line-pack: stretch !important;
	  align-content: stretch !important;
	}
  
	.align-self-md-auto {
	  -ms-flex-item-align: auto !important;
	  align-self: auto !important;
	}
  
	.align-self-md-start {
	  -ms-flex-item-align: start !important;
	  align-self: flex-start !important;
	}
  
	.align-self-md-end {
	  -ms-flex-item-align: end !important;
	  align-self: flex-end !important;
	}
  
	.align-self-md-center {
	  -ms-flex-item-align: center !important;
	  align-self: center !important;
	}
  
	.align-self-md-baseline {
	  -ms-flex-item-align: baseline !important;
	  align-self: baseline !important;
	}
  
	.align-self-md-stretch {
	  -ms-flex-item-align: stretch !important;
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 992px) {
	.flex-lg-row {
	  -ms-flex-direction: row !important;
	  flex-direction: row !important;
	}
  
	.flex-lg-column {
	  -ms-flex-direction: column !important;
	  flex-direction: column !important;
	}
  
	.flex-lg-row-reverse {
	  -ms-flex-direction: row-reverse !important;
	  flex-direction: row-reverse !important;
	}
  
	.flex-lg-column-reverse {
	  -ms-flex-direction: column-reverse !important;
	  flex-direction: column-reverse !important;
	}
  
	.flex-lg-wrap {
	  -ms-flex-wrap: wrap !important;
	  flex-wrap: wrap !important;
	}
  
	.flex-lg-nowrap {
	  -ms-flex-wrap: nowrap !important;
	  flex-wrap: nowrap !important;
	}
  
	.flex-lg-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-lg-fill {
	  -ms-flex: 1 1 auto !important;
	  flex: 1 1 auto !important;
	}
  
	.flex-lg-grow-0 {
	  -ms-flex-positive: 0 !important;
	  flex-grow: 0 !important;
	}
  
	.flex-lg-grow-1 {
	  -ms-flex-positive: 1 !important;
	  flex-grow: 1 !important;
	}
  
	.flex-lg-shrink-0 {
	  -ms-flex-negative: 0 !important;
	  flex-shrink: 0 !important;
	}
  
	.flex-lg-shrink-1 {
	  -ms-flex-negative: 1 !important;
	  flex-shrink: 1 !important;
	}
  
	.justify-content-lg-start {
	  -ms-flex-pack: start !important;
	  justify-content: flex-start !important;
	}
  
	.justify-content-lg-end {
	  -ms-flex-pack: end !important;
	  justify-content: flex-end !important;
	}
  
	.justify-content-lg-center {
	  -ms-flex-pack: center !important;
	  justify-content: center !important;
	}
  
	.justify-content-lg-between {
	  -ms-flex-pack: justify !important;
	  justify-content: space-between !important;
	}
  
	.justify-content-lg-around {
	  -ms-flex-pack: distribute !important;
	  justify-content: space-around !important;
	}
  
	.align-items-lg-start {
	  -ms-flex-align: start !important;
	  align-items: flex-start !important;
	}
  
	.align-items-lg-end {
	  -ms-flex-align: end !important;
	  align-items: flex-end !important;
	}
  
	.align-items-lg-center {
	  -ms-flex-align: center !important;
	  align-items: center !important;
	}
  
	.align-items-lg-baseline {
	  -ms-flex-align: baseline !important;
	  align-items: baseline !important;
	}
  
	.align-items-lg-stretch {
	  -ms-flex-align: stretch !important;
	  align-items: stretch !important;
	}
  
	.align-content-lg-start {
	  -ms-flex-line-pack: start !important;
	  align-content: flex-start !important;
	}
  
	.align-content-lg-end {
	  -ms-flex-line-pack: end !important;
	  align-content: flex-end !important;
	}
  
	.align-content-lg-center {
	  -ms-flex-line-pack: center !important;
	  align-content: center !important;
	}
  
	.align-content-lg-between {
	  -ms-flex-line-pack: justify !important;
	  align-content: space-between !important;
	}
  
	.align-content-lg-around {
	  -ms-flex-line-pack: distribute !important;
	  align-content: space-around !important;
	}
  
	.align-content-lg-stretch {
	  -ms-flex-line-pack: stretch !important;
	  align-content: stretch !important;
	}
  
	.align-self-lg-auto {
	  -ms-flex-item-align: auto !important;
	  align-self: auto !important;
	}
  
	.align-self-lg-start {
	  -ms-flex-item-align: start !important;
	  align-self: flex-start !important;
	}
  
	.align-self-lg-end {
	  -ms-flex-item-align: end !important;
	  align-self: flex-end !important;
	}
  
	.align-self-lg-center {
	  -ms-flex-item-align: center !important;
	  align-self: center !important;
	}
  
	.align-self-lg-baseline {
	  -ms-flex-item-align: baseline !important;
	  align-self: baseline !important;
	}
  
	.align-self-lg-stretch {
	  -ms-flex-item-align: stretch !important;
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 1280px) {
	.flex-xl-row {
	  -ms-flex-direction: row !important;
	  flex-direction: row !important;
	}
  
	.flex-xl-column {
	  -ms-flex-direction: column !important;
	  flex-direction: column !important;
	}
  
	.flex-xl-row-reverse {
	  -ms-flex-direction: row-reverse !important;
	  flex-direction: row-reverse !important;
	}
  
	.flex-xl-column-reverse {
	  -ms-flex-direction: column-reverse !important;
	  flex-direction: column-reverse !important;
	}
  
	.flex-xl-wrap {
	  -ms-flex-wrap: wrap !important;
	  flex-wrap: wrap !important;
	}
  
	.flex-xl-nowrap {
	  -ms-flex-wrap: nowrap !important;
	  flex-wrap: nowrap !important;
	}
  
	.flex-xl-wrap-reverse {
	  -ms-flex-wrap: wrap-reverse !important;
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-xl-fill {
	  -ms-flex: 1 1 auto !important;
	  flex: 1 1 auto !important;
	}
  
	.flex-xl-grow-0 {
	  -ms-flex-positive: 0 !important;
	  flex-grow: 0 !important;
	}
  
	.flex-xl-grow-1 {
	  -ms-flex-positive: 1 !important;
	  flex-grow: 1 !important;
	}
  
	.flex-xl-shrink-0 {
	  -ms-flex-negative: 0 !important;
	  flex-shrink: 0 !important;
	}
  
	.flex-xl-shrink-1 {
	  -ms-flex-negative: 1 !important;
	  flex-shrink: 1 !important;
	}
  
	.justify-content-xl-start {
	  -ms-flex-pack: start !important;
	  justify-content: flex-start !important;
	}
  
	.justify-content-xl-end {
	  -ms-flex-pack: end !important;
	  justify-content: flex-end !important;
	}
  
	.justify-content-xl-center {
	  -ms-flex-pack: center !important;
	  justify-content: center !important;
	}
  
	.justify-content-xl-between {
	  -ms-flex-pack: justify !important;
	  justify-content: space-between !important;
	}
  
	.justify-content-xl-around {
	  -ms-flex-pack: distribute !important;
	  justify-content: space-around !important;
	}
  
	.align-items-xl-start {
	  -ms-flex-align: start !important;
	  align-items: flex-start !important;
	}
  
	.align-items-xl-end {
	  -ms-flex-align: end !important;
	  align-items: flex-end !important;
	}
  
	.align-items-xl-center {
	  -ms-flex-align: center !important;
	  align-items: center !important;
	}
  
	.align-items-xl-baseline {
	  -ms-flex-align: baseline !important;
	  align-items: baseline !important;
	}
  
	.align-items-xl-stretch {
	  -ms-flex-align: stretch !important;
	  align-items: stretch !important;
	}
  
	.align-content-xl-start {
	  -ms-flex-line-pack: start !important;
	  align-content: flex-start !important;
	}
  
	.align-content-xl-end {
	  -ms-flex-line-pack: end !important;
	  align-content: flex-end !important;
	}
  
	.align-content-xl-center {
	  -ms-flex-line-pack: center !important;
	  align-content: center !important;
	}
  
	.align-content-xl-between {
	  -ms-flex-line-pack: justify !important;
	  align-content: space-between !important;
	}
  
	.align-content-xl-around {
	  -ms-flex-line-pack: distribute !important;
	  align-content: space-around !important;
	}
  
	.align-content-xl-stretch {
	  -ms-flex-line-pack: stretch !important;
	  align-content: stretch !important;
	}
  
	.align-self-xl-auto {
	  -ms-flex-item-align: auto !important;
	  align-self: auto !important;
	}
  
	.align-self-xl-start {
	  -ms-flex-item-align: start !important;
	  align-self: flex-start !important;
	}
  
	.align-self-xl-end {
	  -ms-flex-item-align: end !important;
	  align-self: flex-end !important;
	}
  
	.align-self-xl-center {
	  -ms-flex-item-align: center !important;
	  align-self: center !important;
	}
  
	.align-self-xl-baseline {
	  -ms-flex-item-align: baseline !important;
	  align-self: baseline !important;
	}
  
	.align-self-xl-stretch {
	  -ms-flex-item-align: stretch !important;
	  align-self: stretch !important;
	}
  }
  
  .float-left {
	float: left !important;
  }
  
  .float-right {
	float: right !important;
  }
  
  .float-none {
	float: none !important;
  }
  
  @media (min-width: 576px) {
	.float-sm-left {
	  float: left !important;
	}
  
	.float-sm-right {
	  float: right !important;
	}
  
	.float-sm-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 768px) {
	.float-md-left {
	  float: left !important;
	}
  
	.float-md-right {
	  float: right !important;
	}
  
	.float-md-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 992px) {
	.float-lg-left {
	  float: left !important;
	}
  
	.float-lg-right {
	  float: right !important;
	}
  
	.float-lg-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 1280px) {
	.float-xl-left {
	  float: left !important;
	}
  
	.float-xl-right {
	  float: right !important;
	}
  
	.float-xl-none {
	  float: none !important;
	}
  }
  
  .col-text {
	max-width: 48rem;
  }
  
  .col-login {
	max-width: 24rem;
  }
  
  .gutters-0 {
	margin-right: 0;
	margin-left: 0;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	  }
	}
  
	.card {
	  margin-bottom: 0;
	}
  }
  
  .gutters-xs {
	margin-right: -0.25rem;
	margin-left: -0.25rem;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	  }
	}
  
	.card {
	  margin-bottom: 0.5rem;
	}
  }
  
  .gutters-sm {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	  }
	}
  
	.card {
	  margin-bottom: 1rem;
	}
  }
  
  .gutters-lg {
	margin-right: -1rem;
	margin-left: -1rem;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 1rem;
		padding-left: 1rem;
	  }
	}
  
	.card {
	  margin-bottom: 2rem;
	}
  }
  
  .gutters-xl {
	margin-right: -1.5rem;
	margin-left: -1.5rem;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	  }
	}
  
	.card {
	  margin-bottom: 3rem;
	}
  }