.horizontalMenu {
  html, body, iframe, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: antialiased;
    font-smooth: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: subpixel-antialiased;
    font-smooth: subpixel-antialiased;
  }

  .cl {
    clear: both;
  }

  img {
    border: 0 none;
    max-width: 100%;
  }
}

object, embed, video {
  border: 0 none;
  max-width: 100%;
}

.horizontalMenu {
  a:focus {
    outline: none;
  }

  &:before {
    content: "";
    display: table;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* ================== Desktop Main Menu CSS ================== */

.horizontalMenucontainer {
  background-size: cover;
  overflow: hidden !important;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.horizontal-main {
  width: 100%;
  position: relative;
  background: $white;
}

.horizontal-mainwrapper {
  margin: 0 auto;

  /*max-width: 1200px;*/
}

.desktoplogo {
  padding: 1.5rem 0;
  margin: 0;
  float: left;

  img {
    width: 100%;
  }
}

.horizontalMenu {
  color: $white;
  padding: 0;
  display: block;

  > .horizontalMenu-list {
    text-align: left;
    margin: 0 auto;
    padding: 0;
  }
}

.mega-menubg {
  background: $white;
  font-size: 15px;
  padding: 14px 5px;
  box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
  border-radius: 0 0px 4px 4px;
}

.horizontalMenu > .horizontalMenu-list > li {
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
  float: right;

  /*padding: 1.5rem 0;*/

  > a {
    display: block;
    padding: 15px 24px;
    text-decoration: none;
    position: relative;
    margin: 0px;
    color: $color;
    font-size: 14px;
    border-right: 1px solid $border;
    font-weight: 500;
  }

  &:first-child a {
    border-left: 0;
  }

  &:last-child > a {
    border-left: 1px solid $border;
  }

  a {
    &.menuhomeicon {
      padding-left: 25px;
      padding-right: 25px;
    }

    .horizontal-icon {
      margin-left: 0;
    }
  }

  > a i {
    display: inline-block;
    line-height: inherit;
    margin-left: 9px;
    margin-top: 1px;
    font-size: 15px;
  }

  > a .arrow:after {
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 4px solid;
    content: "";
    float: right;
    right: 11px;
    height: 0;
    margin: 0 0 0 14px;
    position: absolute;
    text-align: right;
    top: 33px;
    width: 0;
  }

  &.rightmenu {
    float: right;

    a {
      padding: 0px 30px 0px 20px;
      border-right: none;
    }

    float: right;

    &:before {
      content: "";
      display: table;
    }
  }
}

/* Desktop Search Bar */

.horizontalMenu-list > li.rightmenu:after {
  content: "";
  display: table;
}

.horizontalMenu > .horizontalMenu-list > li {
  &.rightmenu {
    &:after {
      clear: both;
    }

    > .topmenusearch {
      float: right;
      width: 210px;
      height: 39px;
      position: relative;
      margin: 16px 0px 0px 0px;

      .searchicon {
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;
      }

      input {
        width: 100%;
        position: relative;
        float: right;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        text-indent: 15px;
        height: 39px;
        z-index: 2;
        outline: none;
        color: #333;
        background-color: #efefef;
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;
        font-size: 12px;

        &::placeholder {
          color: $color;
        }

        &:focus {
          color: #333;
          width: 230px;

          ~ {
            .btnstyle .fa {
              color: #e1e1f3;
              opacity: 1;
            }

            .searchicon {
              opacity: 1;
              z-index: 3;
              color: #FFFFFF;
            }
          }
        }
      }

      .btnstyle {
        top: 0px;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        line-height: 30px;
        z-index: 1;
        cursor: pointer;
        opacity: 0.3;
        color: #333;
        z-index: 1000;
        background-color: transparent;
        border: solid 0px;
        -webkit-transition: all 0.7s ease 0s;
        -moz-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;

        .fa {
          line-height: 37px;
          margin: 0;
          padding: 0;
          text-align: center;
        }

        &:hover .fa {
          opacity: 1;
        }
      }
    }

    zoom: 1;
  }

  > ul.sub-menu {
    position: absolute;
    top: 54px;
    z-index: 1000;
    margin: 0px;
    padding: 5px;
    min-width: 190px;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
    border-radius: 0 0px 4px 4px;

    > li {
      position: relative;
      margin: 0px;
      padding: 0px;
      display: block;

      > a {
        background-image: none;
        border-right: 0 none;
        text-align: right;
        display: block;
        line-height: 22px;
        padding: 8px 12px;
        color: $color;
        text-transform: none;
        font-size: 13px;
        letter-spacing: normal;
        border-right: 0px solid;
      }
    }
  }
}

/* ================== Desktop Drop Down Menu CSS ================== */

.sub-menu li a:before, .mega-menubg li a:before {
  content: '\e999';
  margin-left: 8px;
  position: relative;
  font-family: 'feather' !important;
  opacity: 0.6;
  float: right;
}

.horizontalMenu > .horizontalMenu-list > li > {
  ul.sub-menu > li > {
    a {
      &:hover {
        text-decoration: none;
      }

      > i {
        margin-right: 9px;
      }
    }

    ul.sub-menu {
      min-width: 220px;
      position: absolute;
      right: 100%;
      top: 0;
      margin: 0;
      padding: 5px;
      list-style: none;
      background-color: $white;
      box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
      border-radius: 0 4px 4px 0;

      > li {
        position: relative;
        margin: 0px;
        padding: 0px;
        display: block;

        > {
          a {
            background-image: none;
            color: $color;
            border-right: 0 none;
            text-align: right;
            display: block;
            line-height: 22px;
            padding: 8px 12px;
            text-transform: none;
            font-size: 13px;
            letter-spacing: normal;
            border-right: 0px solid;

            &:hover {
              text-decoration: none;
            }

            > i {
              margin-right: 9px;
            }
          }

          ul.sub-menu {
            min-width: 220px;
            position: absolute;
            left: 100%;
            top: 0;
            margin: 0px;
            list-style: none;
            padding: 0px;
            background-color: $white;
            border: 1px solid rgba(255, 255, 255, 0.1);

            > li {
              position: relative;
              margin: 0px;
              padding: 0px;
              display: block;

              > a {
                background-image: none;
                color: #e1e1f3;
                border-right: 0 none;
                text-align: left;
                display: block;
                line-height: 22px;
                padding: 8px 12px;
                text-transform: none;
                font-size: 13px;
                letter-spacing: normal;
                border-right: 0px solid;

                &:hover {
                  text-decoration: none;
                }

                > i {
                  margin-right: 9px;
                }
              }
            }
          }
        }
      }
    }
  }

  a {
    &.active, &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  ul.sub-menu {
    .sub-menu-sub:after {
      content: '\e92f';
      font-family: 'feather';
      font-size: 13px;
      position: absolute;
      top: 9px;
      left: 14px;
      color: $color;
      transform: rotate(180deg);
    }

    > li > {
      a:hover, ul.sub-menu > li > a:hover {
        text-decoration: none;
      }
    }
  }

  .horizontal-megamenu {
    .link-list li a:hover {
      text-decoration: none;
    }

    width: 100%;
    left: 0px;
    position: absolute;
    top: 54px;
    color: #e1e1f3;
    z-index: 1000;
    margin: 0px;
    text-align: left;
    font-size: 15px;

    .megamenu-content {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background-color: $white;
      padding: 14px 20px;
    }

    .title {
      padding: 5px 5px 5px 0px;
      font-size: 16px;
      color: #424242;
      margin: 0px 0px 4px 0px;
      text-align: left;
      font-weight: 600;
    }

    .wsmwnutxt {
      width: 100%;
      font-size: 14px;
      text-align: justify;
      line-height: 22px;
      color: #424242;
      margin-top: 10px;
    }

    .link-list li {
      display: block;
      text-align: center;
      white-space: nowrap;
      text-align: left;
      padding: 5px 10px 5px 10px;

      a {
        line-height: 18px;
        border-right: none;
        text-align: right;
        padding: 6px 0px;
        background: $white;
        background-image: none;
        color: $color;
        border-right: 0 none;
        display: block;
        font-size: 13px;
      }
    }

    li {
      i {
        margin-right: 5px;
        text-align: center;
        width: 18px;
      }

      a:hover {
        background: transparent;
        text-decoration: none;
      }
    }

    .link-list li i {
      font-size: 12px;
    }

    li i {
      margin-right: 5px;
      text-align: center;
      width: 18px;
    }

    .mrgtop {
      margin-top: 15px;
    }

    .show-grid div {
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: #dbdbdb;
      border: 1px solid #e7e7e7;
      color: #6a6a6a;
      margin: 2px 0px;
      font-size: 13px;
    }

    &.halfmenu {
      width: 30%;
      right: auto;
      left: auto;
    }

    &.halfdiv {
      width: 20%;
      right: auto;
      left: auto;
    }

    .menu_form {
      width: 100%;
      display: block;

      input[type="text"] {
        width: 100%;
        border: 1px solid #e2e2e2;
        color: #e1e1f3;
        font-size: 13px;
        padding: 8px 5px;
        margin-bottom: 8px;
      }

      textarea {
        width: 100%;
        border: 1px solid #e2e2e2;
        color: #e1e1f3;
        font-size: 13px;
        padding: 8px 5px;
        margin-bottom: 8px;
        min-height: 122px;
      }

      input {
        &[type="submit"] {
          width: 25%;
          display: block;
          height: 32px;
          float: right;
          border: none;
          margin-right: 15px;
          cursor: pointer;
          background-color: #e1e1e1;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }

        &[type="button"] {
          width: 25%;
          display: block;
          height: 32px;
          float: right;
          border: none;
          cursor: pointer;
          background-color: #e1e1e1;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
        }
      }
    }

    .carousel-inner .item img {
      width: 100%;
    }

    .carousel-control-next, .carousel-control-prev {
      opacity: 0.8;
    }

    .carousel-caption {
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 13px;
      height: 31px;
      left: 0;
      padding: 7px 0;
      right: 0;
      width: 100%;
    }

    .typography-text {
      padding: 0px 0px;
      font-size: 14px;

      ul {
        padding: 0px 0px;
        margin: 0px;
      }

      p {
        text-align: justify;
        line-height: 24px;
        color: #656565;
      }

      ul li {
        display: block;
        padding: 2px 0px;
        line-height: 22px;

        a {
          color: #656565;
        }
      }
    }
  }
}

/* ================== Desktop Mega Menus CSS  ================== */

@media (min-width: 1024px) {
  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list:first-child {
    border: 0;
  }

  /*.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list{
  	border-left:1px solid rgba(255,255,255,0.1)
  }*/
}

/*= Desktop Half Menu CSS =*/

/*= Desktop HTML Form Menu CSS =*/

/* ================== Desktop Extra CSS ================== */

.horizontal-header {
  display: none;
}

.overlapblackbg {
  opacity: 0;
  visibility: hidden;
}

.horizontalMenu {
  .horizontalMenu-click, .horizontalMenu-click02 {
    display: none;
  }
}

.hometext {
  display: none;
}

/*==============================================================================
                              Start Mobile CSS
===============================================================================*/

/* ================== Mobile Menu Change Brake Point ================== */

@media only screen and (max-width: 991px) {
  /* ================== Mobile Base CSS ================== */

  body.active {
    overflow: hidden;
  }

  /* ================== Mobile Main Menu CSS ================== */

  .desktoplogo {
    display: none;
  }

  .horizontal-main {
    height: 0;
    padding: 0;
  }

  .horizontalMenucontainer {
    background-attachment: local;
    background-position: 33% 0%;
  }

  .horizontalMenu {
    width: 100%;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    left: 0;
    overflow-y: hidden;
    padding: 0;
    top: 0;
    position: fixed;
    visibility: hidden;
    margin: 0px;

    > .horizontalMenu-list {
      height: auto;
      min-height: 100%;
      width: 240px;
      background: $white;
      padding-bottom: 0;
      margin-right: -240px;
      display: block;
      text-align: center;
      box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.1) !important;

      > li {
        width: 100%;
        display: block;
        float: none;
        border-right: none;
        background-color: transparent;
        position: relative;
        white-space: inherit;
        clear: right;
        padding: 0;
      }
    }
  }

  .header-right-icons .nav-link.leading-none {
    margin-bottom: 0 !important;
  }

  .header .nav-link.leading-none::after {
    top: 24px;
    right: -13px;
  }

  .horizontalMenu > .horizontalMenu-list > li {
    margin: 0;

    > a {
      font-size: 14px;
      text-align: right;
      border-right: solid 0px;
      color: $color;
      line-height: 25px;
      border-bottom: 1px solid #eae8ef;
      position: static;
      border-left: 0;

      span.fa.fa-caret-down {
        display: none;
      }
    }

    a.menuhomeicon {
      padding-left: 17px;
      padding-right: 17px;
      border-top: solid 1px rgba(255, 255, 255, 0.1);
    }

    > a > i {
      font-size: 16px;
    }

    &:hover > a {
      text-decoration: none;
      color: $white;
    }

    > {
      a > .hometext {
        display: inline-block;
      }

      ul.sub-menu {
        display: none;
        position: relative;
        top: 0px;
        background-color: #f7f8fd;
        border: none;
        padding: 0px;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        -webkit-transform-style: flat;
      }
    }
  }

  /* ================== Mobile Slide Down Links CSS ================== */

  .header .nav-link.leading-none::after {
    top: 15px !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > {
    ul.sub-menu {
      > li {
        > a {
          line-height: 20px;
          font-size: 13px;
          padding: 10px 16px 10px 0px;
          color: $color;
        }

        span + a {
          padding-right: 30px;
        }

        > a {
          > i {
            display: none;
          }

          &:hover {
            background-color: #e7e7e7;
            color: #4f5254;
            text-decoration: underline;
          }
        }
      }

      li:hover > a {
        background-color: #e7e7e7;
        color: #4f5254;
      }

      > li > ul.sub-menu {
        width: 100%;
        position: static;
        left: 100%;
        top: 0;
        display: none;
        margin: 0px;
        padding: 0px;
        border: solid 0px;
        transform: none;
        opacity: 1;
        visibility: visible;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
        -webkit-transform-style: flat;

        > li {
          margin: 0px 0px 0px 0px;
          padding: 0px;
          position: relative;

          > a {
            line-height: 20px;
            font-size: 13px;
            padding: 10px 26px 10px 0px;
          }

          span + a {
            padding-right: 30px;
          }

          > a {
            > i {
              display: none;
            }

            &.active {
              color: #e1e1f3;
            }
          }

          &:hover > a {
            color: #e1e1f3;
          }

          > ul.sub-menu {
            width: 100%;
            position: static;
            left: 100%;
            top: 0;
            display: none;
            margin: 0px;
            padding: 0px;
            border: solid 0px;
            transform: none;
            opacity: 1;
            visibility: visible;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            transition: inherit;
            -webkit-transform-style: flat;

            > li {
              margin: 0px 0px 0px 0px;

              > a {
                line-height: 20px;
                font-size: 13px;
                padding: 10px 0px 10px 34px;
                color: #e1e1f3;
              }

              span + a {
                padding-right: 30px;
              }

              > a {
                > i {
                  display: none;
                }

                &:hover {
                  background-color: #e7e7e7;
                  color: #e1e1f3;
                  text-decoration: underline;
                }

                &.active {
                  color: #e1e1f3;
                }
              }
            }
          }
        }
      }
    }

    .horizontal-megamenu {
      color: #e1e1f3;
      display: none;
      position: relative;
      top: 0px;
      padding: 10px 0px;
      border: solid 0px;
      transform: none;
      opacity: 1;
      visibility: visible;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      -webkit-transition: inherit;
      -moz-transition: inherit;
      transition: inherit;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      -webkit-transform-style: flat;
      background: #f7f8fd;
    }

    a {
      padding: 0.55rem 1.35rem 0.55rem;
    }

    .horizontal-megamenu {
      &.halfmenu {
        width: 100%;
        margin: 0px;
        padding: 5px 0px 10px 0px;
      }

      .title {
        color: #e1e1f3;
        font-size: 15px;
        padding: 10px 8px 10px 0px;
      }

      > ul {
        width: 100%;
        margin: 0px;
        padding: 0px;
        font-size: 13px;

        > li > a {
          padding: 9px 14px;
          line-height: normal;
          font-size: 13px;
          background-color: #e7e7e7;
          color: #e1e1f3;

          &:hover {
            background-color: #e1e1f3000;
          }
        }
      }

      ul li.title {
        line-height: 26px;
        color: #e1e1f3;
        margin: 0px;
        font-size: 15px;
        padding: 7px 0px;
        background-color: transparent;
      }

      &.halfdiv {
        width: 100%;
      }

      .menu_form {
        padding: 5px 0px 62px 0px;
      }

      .show-grid div {
        margin: 0px;
      }

      .menu_form {
        input {
          &[type="button"], &[type="submit"] {
            width: 46%;
          }
        }

        textarea {
          min-height: 100px;
        }
      }
    }
  }

  /* ================== Mobile Mega Menus CSS  ================== */

  /* ================== Mobile Header CSS ================== */

  .horizontal-header {
    width: 100%;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    height: 54px;
    background-color: #eaecf0;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .active .horizontal-header {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .horizontal-header .smllogo {
    display: inline-block;
    margin-top: 15px;
  }

  /* Mobile Search Bar*/

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
    width: 86%;
    margin: 7% 7%;
    padding: 0px;

    input {
      border-radius: 50px;

      &:focus {
        width: 100%;
      }
    }
  }

  .callusbtn {
    color: #e1e1f3;
    font-size: 21px;
    position: absolute;
    right: 5px;
    top: 0px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 102;
    padding: 11px 14px;

    .fa {
      vertical-align: top;
      margin-top: 4px;
    }

    &:hover .fa {
      color: #e1e1f3;
    }
  }

  /* Mobile Toggle Menu icon (X ICON) */

  .animated-arrow {
    position: absolute;
    top: -5px;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    padding: 86px 35px 16px 0px;
    margin: 11px 0 0 -11px;

    span {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: $white;
      position: absolute;
      display: block;
      content: '';

      &:before, &:after {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: $white;
        position: absolute;
        display: block;
        content: '';
      }

      &:before {
        top: -6px;
        width: 23px;
      }

      &:after {
        bottom: -6px;
        width: 17px;
      }

      transition: all 500ms ease-in-out;

      &:before, &:after {
        transition: all 500ms ease-in-out;
      }
    }
  }

  .active .animated-arrow {
    span {
      &:after {
        width: 23px;
        top: -3px;
      }

      background-color: transparent;

      &:before {
        top: -3px;
      }
    }

    &.active span:after {
      top: -3px;
    }

    span {
      &:before {
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: 0px;
      }

      &:after {
        transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  /* ================== Mobile Overlay/Drawer CSS ================== */

  .overlapblackbg {
    left: 0;
    width: calc(100% - 240px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0; 
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  .active .horizontalMenu .overlapblackbg {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
  }

  .horizontalMenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .active {
    .horizontalMenucontainer {
      margin-right: 240px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }

    .horizontalMenu {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      visibility: visible;
      z-index: 991;
      top: 68px;
      height: 100% !important;

      > .horizontalMenu-list {
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        margin-right: 0;
      }
    }
    .horizontalMenu > .horizontalMenu-list > li:last-child > a {
        border-left: 1px solid transparent;
    }
  }

  /* ================== Mobile Sub Menu Expander Arrows  ================== */

  .horizontalMenu > .horizontalMenu-list > li {
    > .horizontalMenu-click {
      height: 49px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      cursor: pointer;
      width: 100%;
    }

    .horizontalMenu-click.ws-activearrow > i {
      transform: rotate(180deg);
      margin-top: 8px;
    }

    > .horizontalMenu-click > i {
      display: block;
      height: 23px;
      width: 25px;
      margin-top: 15px;
      margin-right: 10px;
      background-size: 25px;
      font-size: 16px;
      color: #90a1bb;
      float: left;
    }
  }

  .horizontalMenu-click.ws-activearrow {
    color: #90a1bb;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li .horizontalMenu-click02 {
    height: 41px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%;

    > i {
      display: block;
      height: 23px;
      width: 25px;
      margin: 12px 6px 8px 6px;
      background-size: 25px;
      font-size: 16px;
      color: #90a1bb !important;
      float: left;

      &.horizontalMenu-rotate {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        margin-top: 6px;
      }
    }
  }

  .mega-menubg {
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;
    background: #f7f8fd;
  }

  .horizontalMenu > .horizontalMenu-list > li > {
    .horizontal-megamenu .link-list li a {
      background: #f7f8fd;
    }

    a {
      &.sub-icon:before, &:hover.sub-icon:before {
        display: none;
      }
    }

    ul.sub-menu .sub-menu-sub:after, a .hor-icon {
      display: none;
    }

    ul.sub-menu {
      > li > a:hover {
        background: $white;
        color: #7673e6;
        text-decoration: none;
      }

      li:hover > a {
        background: transparent;
      }

      > li {
        &:hover > a {
          color: $white;
          text-decoration: none;
        }

        > ul.sub-menu {
          box-shadow: none !important;
          background: $white;
          border-radius: 0px !important;
        }
      }
    }
  }

  .mega-menubg {
    &:before {
      display: none;
    }

    ul {
      padding: 0;
    }
  }

  .horizontalMenu > .horizontalMenu-list > li {
    > {
      ul.sub-menu {
        box-shadow: none;
        border-radius: 0 !important;

        &:before {
          display: none;
        }
      }

      a.active > .fa {
        color: $white !important;
      }
    }

    &:hover > a > .fa {
      color: $white !important;
    }

    > a > .horizontal-icon {
      display: none;
    }
  }

  .active .hor-menu .horizontalMenu {
    top: 0 !important;
  }

  .hor-toggle.animated-arrow {
    padding: 20px 25px 16px 10px !important;
    margin: 11px 0 0 0px !important;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active {
      color: $white !important;
    }

    &:hover {
      color: $white;
    }
  }

  .content-area {
    margin-top: 61px;
  }

  .active .content-area {
    margin-top: 0px;
  }

  .horizontalMenu > .horizontalMenu-list > li:last-child > a {
    border-right: 0;
  }

  /*End Media Query*/
}

/* Extra @Media Query*/

@media (min-width: 992px) and (max-width: 1024px) {
  .horizontalMenu > .horizontalMenu-list > li {
    font-size: 10px;

    > a .fa {
      margin-right: 5px;
      margin-top: 0px !important;
    }
  }

  .header-horizonatlmenu .horizontalMenu > .horizontalMenu-list > li > a {
    font-size: 10px;
    padding: 1rem 0.6rem 1rem !important;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    font-size: 14px !important;

    > a .fa {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .horizontalMenu > .horizontalMenu-list > li > a {
    .fa {
      margin-top: 0px !important;
    }

    margin: 0px !important;
    padding: 1rem 0.7rem 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1162px) {
  .desktoplogo {
    margin-left: 12px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .arrow {
    display: none;
  }
}

@media (max-width: 1025px) and (min-width: 992px) {
  .horizontalMenu > .horizontalMenu-list > li > a {
    padding: 15px 7px !important;
  }
}

@media (min-width: 992px) {
  .header-desktop {
    display: block;
    position: absolute;
    width: 150px;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: 2px;
  }

  .color-headerlogo .header-mobile {
    display: none;
  }

  .hor-menu.horizontal-main {
    box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
    margin-top: 63px;
    position: fixed;
    z-index: 10;
  }

}

@media (max-width: 992px) and (min-width: 767px) {
  .header .form-inline {
    margin-right: 13rem;
  }

  .header-desktop {
    margin-right: 3rem;
    display: block;
    position: absolute;
    width: 150px;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: 2px;
  }

  .search-icon {
    margin-top: 3px;
  }
}

@media (max-width: 767.98px) and (min-width: 576px) {
  .header-desktop {
    margin-right: 3rem;
    display: block;
    position: absolute;
    width: 150px;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: 2px;
  }

  .search-icon {
    margin-top: 3px;
  }
}

@media (max-width: 575.98px) {
  .color-headerlogo .header-mobile {
    display: block;
    position: absolute;
    width: 50px;
    height: 42px;
    background-repeat: no-repeat;
    margin-right: 3rem;
    margin-top: 0px;
  }
}


  
 @media (min-width: 992px){
	  .app .horizontalMenucontainer .page-header {
	    margin-top: 7.9rem;
	  }
	  .app.sidebar-mini .horizontalMenucontainer .page-header {
	    margin-top: 0.55rem;
  }
}



@media only screen and (max-width: 991px){
	.active {

		.horizontal-main.horizontal-main1 .horizontalMenu  ,.horizontal-main.horizontal-main2 .horizontalMenu, .horizontal-main.horizontal-main3 .horizontalMenu {
		    z-index: 1;
		    visibility:hidden;
		}
	}
	
	.header.header-1,.header.header-2,.header.header-style{
	 z-index: 1;
	}
}