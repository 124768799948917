@media (max-width: 480px) {
	.breadcrumb {
	  flex-wrap: wrap;
	  margin-bottom: 0;
	  list-style: none;
	  border-radius: 4px;
	  position: relative;
	  right: 0;
	  top: 0px;
	  padding-right: 0;
	  color: $white;
	  left: 0px;
	  padding: 0;
	  margin-top: 5px;
	}
  }
  
  /*------ Breadcrumb ------*/
  
  .breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	list-style: none;
	border-radius: 4px;
	position: relative;
	right: 0;
	padding-right: 0;
	color: $color;
	padding-left: 0;
  }
  
  .breadcrumb-item {
	a {
	  color: $color;
	}
  
	+ .breadcrumb-item {
	  &::before {
		display: inline-block;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		color: #b0bac9;
		content: "/";
	  }
  
	  &:hover::before {
		text-decoration: underline;
		text-decoration: none;
	  }
	}
  }
  
  .breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: rgba(236, 238, 249, 0.9);
	border-radius: .25rem;
  }
  
  .breadcrumb-item1 {
	a:hover {
	  color: #123787;
	  text-decoration: underline;
	}
  
	+ .breadcrumb-item1 {
	  &::before {
		display: inline-block;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		content: "/";
		color: #cec5c5;
	  }
  
	  &:hover::before {
		text-decoration: underline;
		text-decoration: none;
	  }
	}
  
	&.active {
	  color: $color;
	}
  }
  
  .breadcrumb-3 {
	ol {
	  position: relative;
	  display: block;
	  width: 100%;
	  text-align: center;
	}
  
	li {
	  display: contents;
	  text-align: center;
	}
  }
  
  .breadcrumb-4 li, .breadcrumb-5 li {
	display: contents;
	text-align: center;
  }
  
  .breadcrumb-4 ol, .breadcrumb-5 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: center;
  }
  
  .breadcrumb-1 li, .breadcrumb-2 li, .breadcrumb-6 li {
	text-align: right;
	display: contents;
  }
  
  .breadcrumb-1 ol, .breadcrumb-2 ol, .breadcrumb-6 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: left;
  }
  
  .breadcrumb-item + .breadcrumb-item {
	color: $color;
  }
  
  @media (max-width: 992px) {
	.breadcrumb {
	  right: 15px;
	}
  }