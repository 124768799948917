.footer {
  border-top: 0;
  font-size: 0.875rem;
  padding: 1.25rem 0;
  color: $color;
  background: $white;
  box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);

  .container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .social {
    ul li {
      float: right;
      padding: 7px;

      a {
        color: #8c8ea9;
      }
    }

    margin: 0 auto;
  }
}

@media print {
  .footer {
    display: none;
  }
}

@media (max-width: 767px) {
  .footer .privacy {
    text-align: center !important;
  }
}

.top-footer {
  width: 100%;
  line-height: 1.25rem;
  opacity: 1;
  transform: translate(0px, 0px);
  font-size: 14px;
  flex: 0 0 auto;

  h6 {
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    color: #8c8ea9;
  }

  a {
    color: #90a1bb;
    line-height: 2;
    color: #90a1bb;
    line-height: 2;

    address {
      color: #90a1bb;
      margin-bottom: 0;
      line-height: 2;
    }
  }

  img {
    width: 80px;
    border: 1px solid #e6eaea;
    padding: 2px;
    height: 80px;

    &:hover {
      color: #8e9090;
      opacity: .6;
    }
  }

  .list-unstyled li {
    border-bottom: 0;
  }
}

.footer-payments {
  padding-left: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;

  li {
    &:not(:last-child) {
      margin-left: 1.5rem;
    }

    display: inline-block;
  }

  a {
    color: #a7a8c7;
    text-decoration: none;
    font-size: 2rem;

    &:hover {
      opacity: .9;
    }
  }
}

.main-footer {
  padding: 20px 30px 20px 280px;
  font-size: 14px;
  font-weight: 400;
  background-color: $white;
  border-top: 1px solid #e6eaea;
}

.footer-social-list {
  li {
    &:not(:last-child) {
      margin-left: 1.5rem;
    }

    display: inline-block;
  }

  a {
    color: $color;
    text-decoration: none;
    font-size: 1rem;
  }

  padding-left: 0;
  list-style: none;
  margin: 0;
  line-height: 1.25rem;
}

@media (max-width: 992px) {
  .footer-social-list {
    text-align: center;
    margin-top: 1rem;
  }

  .footer1 {
    text-align: center;
  }
}